import { call, put, takeLatest } from 'redux-saga/effects';
import { StoryStoryblok } from 'src/typings';
import { getAllStories } from 'src/util';

import { GET_STORIES } from '../ActionTypes';
import * as CreateStoriesActions from './Actions';

export function* getStoryData() {
  try {
    const stories: StoryStoryblok[] = yield call(getAllStories);
    // const {navigation, fromMain} = payload;
    yield put(CreateStoriesActions.createStoryDataSuccess(stories));
  } catch (e) {
    yield put(CreateStoriesActions.createStoryDataError(e));
  }
}

export default function* () {
  yield takeLatest(GET_STORIES, getStoryData);
}
