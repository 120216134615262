// @flow

import {
  GET_CURRENT_PRICES,
  GET_PRICES_SUCCESS,
  GET_PRICES_ERROR,
  GET_TOTAL_STAKES,
  GET_TOTAL_STAKES_SUCCESS,
  GET_TOTAL_STAKES_ERROR,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';

export interface ERC20Prices {
  game: number;
  genesis: number;
  eth: number;
  matic: number;
  quick: number;
  usdc: number;
  usdt: number;
}

export type ERC20State = {
  prices: ERC20Prices;
  totalStakes: {
    farm: string;
    governance: string;
  };
  error: string;
};

const INITIAL_STATE: ERC20State = {
  prices: {
    game: 0,
    genesis: 0,
    eth: 0,
    matic: 0,
    quick: 0,
    usdc: 1,
    usdt: 1,
  },
  totalStakes: {
    farm: '0',
    governance: '0',
  },
  error: '',
};

const reducers = {
  [GET_CURRENT_PRICES]: (state) => {
    return state;
  },
  [GET_PRICES_SUCCESS]: (state, { data }) => ({
    ...state,
    prices: data,
  }),
  [GET_PRICES_ERROR]: (state, { error }) => ({
    ...state,
    error,
  }),
  [GET_TOTAL_STAKES]: (state) => {
    return state;
  },
  [GET_TOTAL_STAKES_SUCCESS]: (state, { data }) => ({
    ...state,
    totalStakes: data,
  }),
  [GET_TOTAL_STAKES_ERROR]: (state, { error }) => ({
    ...state,
    error,
  }),
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
