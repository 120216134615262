// @flow

import {
  GET_BONDED,
  GET_BONDED_SUCCESS,
  GET_BONDED_SUPPLY_SUCCESS,
  GET_BONDED_ERROR,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';

export type WorldsState = {
  bondedData: any;
  isFetching: boolean;
  error: any;
};

const INITIAL_STATE: WorldsState = {
  bondedData: {},
  isFetching: false,
  error: null,
};

const reducers = {
  [GET_BONDED]: (state) => ({ ...state, isFetching: true }),
  [GET_BONDED_SUCCESS]: (state, { payload }) => ({
    ...state,
    bondedData: { ...payload },
    isFetching: false,
  }),
  [GET_BONDED_SUPPLY_SUCCESS]: (state, { payload }) => ({
    ...state,
    bondedSupply: { ...payload },
    isFetching: false,
  }),
  [GET_BONDED_ERROR]: (state, { error }) => ({
    ...state,
    bondedData: {},
    error,
    isFetching: false,
  }),
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
