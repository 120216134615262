import { Colors } from 'src/constants/colors';
import { theme } from 'src/theme';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import { GenesisPadding, mobileGutter } from '../cmsdriven';

export const DataTableBody = styled.div(
  (props) => {
    return {
      fontFamily: 'Montserrat',
      display: 'flex',
      flexDirection: 'column',
      margin: `0 auto ${GenesisPadding.mobileDouble}`,
      maxWidth: '375px',
    };
  },
  space,
  color,
);

export const DataTableHeader = styled.div(
  (props) => {
    return {
      fontFamily: 'Montserrat',
      padding: '8px 0',
      textTransform: 'uppercase',
      fontWeight: 600,
      textAlign: 'center',
      // borderTop: `solid 1px ${Colors.blue}`,
      borderBottom: `solid 1px ${Colors.blue}`,
    };
  },
  space,
  color,
);
export const DataTableRow = styled.div(
  (props) => {
    return {
      padding: '8px 0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
      borderBottom: `solid 1px ${Colors.blue}`,
    };
  },
  space,
  color,
);
export const DataTableData = styled.p(
  (props) => {
    return {
      textAlign: 'right',
      width: '40%',
      padding: '0 8px 0 0',
      margin: 0,
      fontSize: theme.fontSizes[0],
    };
  },
  space,
  color,
);
export const DataTableLabel = styled.p(
  (props) => {
    return {
      textAlign: 'left',
      width: '60%',
      padding: '0 0 0 8px',
      margin: 0,
      fontSize: theme.fontSizes[0],
    };
  },
  space,
  color,
);
export const DataTableCta = styled.div(
  (props) => {
    return {
      padding: mobileGutter,
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
    };
  },
  space,
  color,
);
