import React from 'react';
import styled from 'styled-components';

export function validateEmail(email: string) {
  return (
    /\S+@\S+\.\S+/.test(email) && // Is formatted reasonably well
    email.indexOf(' ') === -1 && // Has no spaces technically incorrect but helpful
    email.indexOf('..') === -1 // Has no double periods technically incorrect but helpful
  );
}

export function splitPascalCase(s: string) {
  return s ? s.split(/(?=[A-Z])/).join(' ') : '';
}

export function getDateTimeString(timestamp: number) {
  return new Date(timestamp).toString().split('GMT')[0];
}

export function getWholeGC(gc: string) {
  const noLeadingZeroes = removeLeadingZeroes(gc);
  if (noLeadingZeroes.length === 0) {
    return gc.length <= 18 ? 0 : parseInt(gc.slice(0, -18), 10);
  } else {
    return noLeadingZeroes.length <= 18
      ? 0
      : parseInt(noLeadingZeroes.slice(0, -18), 10);
  }
}

export function getDecimalGC(gc: string, decimals: number) {
  const noLeadingZeroes = removeLeadingZeroes(gc);
  const sublength = 18 - decimals;
  if (noLeadingZeroes.length === 0) {
    return gc.length <= sublength ? '0' : gc.slice(0, -sublength);
  } else {
    return noLeadingZeroes.length <= sublength
      ? '0'
      : noLeadingZeroes.slice(0, -sublength);
  }
}

export function getstring(wholeGC: string) {
  return !wholeGC ? '0' : `${removeNonNumbers(wholeGC)}000000000000000000`;
}

export function removeNonNumbers(numberString: string) {
  return !numberString ? '0' : numberString.replace(/\D+/g, '');
}

export function removeLeadingZeroes(numberString: string) {
  return !numberString ? '0' : numberString.replace(/^0+(?!\.|$)/, '');
}

export function displayFormGC(gc: string) {
  return `${getWholeGC(gc).toLocaleString()}`;
}

export const WholeGC = styled.span({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  color: '#81c341',
});

export const WholeGCImg = styled.img({
  marginLeft: '2px',
  filter: 'brightness(0%) invert(1)',
});

export function displayWholeGC(gc: string, punc?: string) {
  return (
    <WholeGC>
      <b>{getWholeGC(gc).toLocaleString()}</b>
      {/*'\u00A0'*/}
      <WholeGCImg
        className="svg-inline--fa"
        src="/images/GC_Currency_G_Mark.svg"
      />
      {punc}
    </WholeGC>
  );
}

export function displayExactGC(gc: number, decimals: number, punc?: string) {
  return (
    <WholeGC>
      <b>{+parseFloat(gc.toFixed(decimals)).toLocaleString()}</b>
      <WholeGCImg
        className="svg-inline--fa"
        src="/images/GC_Currency_G_Mark.svg"
      />
      {punc}
    </WholeGC>
  );
}

export function displayWholeETH(eth: string) {
  return `${getWholeGC(eth).toLocaleString()}\u00A0ETH`;
}

export function displayDecimalETH(eth: string, decimals: number) {
  return `${(
    parseFloat(getDecimalGC(eth, decimals)) /
    10 ** decimals
  ).toLocaleString()} \u00A0ETH`;
}
