import React from 'react';
import PropTypes from 'prop-types';

// components
import { Wrapper, MenuOption } from './style';

export const CustomDropDown = (props) => {
  const { top, height, data = [], onToggle, dropUp } = props;

  return (
    <Wrapper top={top} height={height} onClick={onToggle} dropUp={dropUp}>
      {data.map((item) => {
        return <MenuOption key={item}>{item}</MenuOption>;
      })}
    </Wrapper>
  );
};

CustomDropDown.propTypes = {
  top: PropTypes.string,
  height: PropTypes.string,
  data: PropTypes.array,
  onToggle: PropTypes.func,
  dropUp: PropTypes.bool,
};
