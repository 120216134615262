import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import LinesEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { useWindowSize } from 'src/hooks/useWindowSize';

import { BackButton } from 'src/components/buttons/backButton';
import {
  TagContainer,
  TagRow,
  ContentContainer,
  MobileProps,
} from 'src/components';
import { Colors } from 'src/constants/colors';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const ItemDescription = styled.div<MobileProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: ${props.mobile ? '0' : '32px'};
  `,
);

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const ItemPrice = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: ${(props) => props.theme.colors.red};
  margin: 12px 0 0 16px;
`;

const ItemPriceUnit = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin-top: 22px;
`;

const ItemPriceContainer = styled.div`
  margin: 2px 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface ItemPriceTextProps {
  inner?: boolean;
}

const ItemPriceText = styled.div<ItemPriceTextProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: ${props.inner ? '400' : '500'};
    font-size: 16px;
    line-height: 24px;
    color: ${props.theme.colors.darkGray};
    display: flex;
    flex-direction: row;
  `,
);

const BuyBtn = styled.div`
  height: 40px;
  background-color: ${(props) => props.theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
`;

const RedeemButton = styled(BuyBtn)`
  background-color: ${(props) => props.theme.colors.green};
  margin: 8px 0;
`;

const OwnerText = styled(ItemPriceText)`
  margin: 16px 0 8px;
`;

const UnderlineText = styled(ItemPriceText)`
  color: ${(props) => props.theme.colors.blue};
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
`;

const NFTDetail = () => {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const [numLine, setNumLine] = useState(5);
  const [nftData] = useState({
    image:
      'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
    col: 'col1',
    name: 'item1',
    price: 1,
    descrtiption:
      'A cargo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. oo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. You’ll find specialized types of cargo vessels include port ships, containers, soso on',
  });

  return (
    <ContentContainer mobile={isMobile}>
      {!isMobile && <BackButton backUrl="/market"></BackButton>}
      <ItemDescription mobile={isMobile}>{`Creator's name`}</ItemDescription>
      <ItemTitle>
        Name of this NFTName of this NFTName of this NFTName of this NFT
      </ItemTitle>
      <CardContainer>
        <ItemPrice>
          990
          <ItemPriceUnit>GAME</ItemPriceUnit>
        </ItemPrice>
        <ItemPriceContainer>
          <ItemPriceText inner>{'0 copies'}</ItemPriceText>
          <ItemPriceText inner>{'Burn: 562-5400 GAME'}</ItemPriceText>
        </ItemPriceContainer>
        <BuyBtn onClick={() => history.push('/market/123/fund')}>
          {'BUY'}
        </BuyBtn>
      </CardContainer>
      <ItemPriceText>DESCRIPTION</ItemPriceText>
      <ResponsiveEllipsis
        onClick={() => setNumLine(numLine === 5 ? 1000 : 5)}
        unsafeHTML={`<span style="font-size: 17px; line-height: 24px; color: ${Colors.gray6}; font-weight: 500;">${nftData.descrtiption}</span>`}
        maxLine={numLine}
        ellipsisHTML={`<span>...&nbsp;<span style="color: ${Colors.black}; cursor: pointer;"><u>Read More</u></span></span>`}
        basedOn="letters"
      />
      <OwnerText>OWNED: 5</OwnerText>
      <RedeemButton onClick={() => history.push('/market/123/fund')}>
        {'REDEEM: 5,400 GAME'}
      </RedeemButton>
      <OwnerText>TAGS</OwnerText>
      <TagRow>
        <TagContainer>Tag One</TagContainer>
        <TagContainer>Tag Two</TagContainer>
        <TagContainer>Tag Three</TagContainer>
        <TagContainer>Tag</TagContainer>
        <TagContainer>Tag Two</TagContainer>
        <TagContainer>Tag Three</TagContainer>
      </TagRow>
      <OwnerText>TRENDS</OwnerText>
      <ItemPriceText>
        {'128 bought | 71 sold | '}
        <UnderlineText>Bonding curve</UnderlineText>
      </ItemPriceText>
    </ContentContainer>
  );
};

export default NFTDetail;
