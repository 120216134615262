import styled from 'styled-components';
import {
  space,
  color,
  flex,
  FlexProps,
  ColorProps,
  SpaceProps,
} from 'styled-system';
import { FiChevronDown } from 'react-icons/fi';
import { MobileProps, MenuOpenProps } from '..';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps & ColorProps & MobileProps;

export const Wrapper = styled.div<WrapperProps>(
  (props) => ({
    height: props.mobile ? '48px' : '72px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid transparent',
    background: '#fff',
    overflow: 'overlay',
    top: 0,
    left: 0,
    right: 0,
    position: 'sticky',
    zIndex: 900,
  }),
  space,
  color,
);

export const HeaderBackground = styled.div<WrapperProps>(
  (props) => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: '#fff',
    zIndex: 901,
  }),
  space,
  color,
);

export const MenuBody = styled.div<WrapperProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.4)',
}));

type MobileMenuProps = {
  expanded: boolean;
};

export const MobileMenu = styled.div<MobileMenuProps>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 899,
  top: '48px',
  left: 0,
  width: '100vw',
  height: '100vh',
  transition: 'transform 0.3s ease-in-out',
  backgroundColor: '#0000',
  transform: props.expanded ? 'translateY(0)' : 'translateY(-120%)',
}));

type RowProps = FlexProps & MobileProps;

export const Row = styled.div<RowProps>(
  (props) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 902,
    background: '#fff',
    padding: props.mobile ? '0' : '0 16px',
  }),
  flex,
);

export const LogoImage = styled.img<MobileProps>((props) => ({
  width: props.mobile ? '34px' : '50px',
  height: props.mobile ? '34px' : '50px',
  margin: '0 8px',
  cursor: 'pointer',
  outline: 'none',
}));

export const InputContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid rgb(229, 232, 235)',
  borderRadius: '5px',
  color: 'rgba(14, 14, 14, 0.75)',
  height: '45px',
  padding: '0 0 0 10px',
  margin: '0',
  width: '40%',
  marginLeft: 'auto',
});

export const SearchIcon = styled.img<MobileProps>((props) => ({
  width: props.mobile ? '48px' : '48px',
  height: '48px',
}));

export const DownIcon = styled(FiChevronDown)({
  width: '24px',
  height: '24px',
});

export const AccountIcon = styled.img<MenuOptionProps>((props) => ({
  width: '48px',
  height: '48px',
  color: props.isActive && props.theme.colors.lightGreen,
}));

export const MenuIcon = styled.img<MenuOpenProps>((props) => ({
  width: '48px',
  height: props.menuOpen ? '20px' : '48px',
}));

export const SearchField = styled.input({
  flex: 1,
  cursor: 'text',
  letterSpacing: 'normal',
  wordSpacing: 'normal',
  textTransform: 'none',
  textIndent: '0',
  textShadow: 'none',
  display: 'inline-block',
  textAlign: 'start',
  appearance: 'auto',
  backgroundColor: 'transparent',
  color: 'rgba(14, 14, 14, 0.75)',
  border: 'none',
  marginLeft: '8px',
  outline: 'none',
});

export const ButtonContainer = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  overflow: 'hidden',
  height: '48px',
  margin: '0',
});

export const MarkerImg = styled.img({
  height: '10px',
  position: 'absolute',
  bottom: '-17px',
  objectFit: 'contain',
  objectPosition: 'center',
  left: '0',
  width: '100%',
});

type DesktopButtonProps = MobileProps &
  MenuOpenProps & {
    isActive: boolean;
    isButton: boolean;
    isRight?: boolean;
  };

export const DesktopButton = styled.h6<DesktopButtonProps>((props) => ({
  display: 'flex',
  height: '48px',
  cursor: 'pointer',
  lineHeight: '40px',
  color: props.isActive && props.theme.colors.lightGreen,
  textDecoration: props.isActive && 'underline',
  // border: props.isActive ? '2px solid rgb(32, 129, 226)' : '2px solid #00000000',
  // borderWidth: '0 0 2px 0',
  // marginRight: props.isButton ? '0' : '8px',
  padding: props.isButton ? '16px 0' : props.mobile ? '16px 8px' : '16px',
  alignItems: 'center',
  position: 'relative',
  marginLeft: props.isRight ? 'auto' : 0,
  textUnderlineOffset: '8px',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface MenuOptionProps {
  isMobile: boolean;
  isActive: boolean;
  first?: boolean;
  last?: boolean;
}

export const MenuOption = styled.h6<MenuOptionProps>((props) => ({
  height: props.first ? '72px' : props.last ? '96px' : '48px',
  padding: '0 24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontSize: theme.fontSizes[1],
  fontWeight: props.isActive && 700,
  cursor: 'pointer',
  color: props.isActive && props.theme.colors.lightGreen,
  // backgroundColor: '#E5E5E5',
  paddingTop: props.first ? '24px' : '0',
  paddingBottom: props.last ? '48px' : '0',
  margin: props.isMobile ? 'auto' : '0',
}));

export const BlurDiv = styled.div`
  display: flex;
  flex: 1;
  background: rgba(196, 196, 196, 0.01);
  backdrop-filter: blur(10px);
`;
