export const desktopGutter = '48px';
export const mobileGutter = '16px';
export const maxContentWidth = '800px';

export const standardObjectWidth = '280px';
export const largeCardWidth = standardObjectWidth;
export const smallCardWidth = '164px';
export const buttonHeight = '40px';

export enum WindowSize {
  desktop = '@media(min-width: 768px)',
}

export enum FontSize {
  mobileHeading = '24px',
  mobileSubHead = '16px',
  mobileBody = '16px',
  desktopHeading = '28px',
  desktopSubHead = '20px',
  desktopBody = '20px',
  title = '48px',
}

export enum GenesisPadding {
  mobileSingle = '16px',
  mobileDouble = '32px',
  desktopSingle = '24px',
  desktopDouble = '48px',
}

export enum GenesisColor {
  blue = '#25A4D6',
  red = '#C4033C',
  purple = '#664FC9',
  white = '#FFFFFF',
  greyLight = '#CCCCCC',
  greyMid = '#6d6d6d',
  greyDark = '#434343',
  black = '#000000',
}

export enum DesktopLayout {
  Full = 'full',
  Left = 'left',
  Right = 'right',
}
