import React from "react"
import { SelectPicker } from "rsuite"
import { useWindowSize } from "src/hooks/useWindowSize"

const SortBy = () => {
  const options = ['Most purchased', 'Least purchased', 'Recently created', 'Oldest', 'Price low > high', 'Price high > low', 'Most viwed']
  const { isMobile } = useWindowSize()

  const data = options.map((option) => ({
    label: option,
    value: option
  }))
  return (
      <SelectPicker data={data} style={{ height: 48, width: isMobile ? '100%' : 200, marginTop: 5, marginLeft: 'auto' }} placeholder="Sort By" />
  )
}

export default SortBy