import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';

type WrapperProps = SpaceProps & ColorProps;

export const FarmGenesisSectionStyle = styled.div<WrapperProps>(
  (props) => ({
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  space,
  color,
);
