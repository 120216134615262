import styled from 'styled-components';
import { Parallax } from 'react-parallax';
import LazyLoad from 'react-lazyload';

export interface MenuOpenProps {
  menuOpen?: boolean;
}

export interface MobileProps {
  mobile?: boolean;
}

export interface TitleProps {
  header?: boolean;
}

export interface MainProps {
  main?: boolean;
}

export interface LazyProps {
  lazy?: boolean;
}

export const Header = styled.header({
  backgroundColor: '#282c34',
  minHeight: '70px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: 'white',
});

export const Body = styled.div({
  position: 'relative',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 'calc(10px + 2vmin)',
  justifyContent: 'center',
  minHeight: 'calc(100vh - 72px)',
  width: '100%',
});

export const Main = styled.main({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  minHeight: 'calc(100vh - 180px)',
  width: '100%',
});

export const Image = styled.img({
  height: '40vmin',
  marginBottom: '16px',
  pointerEvents: 'none',
});

export const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  color: '#61dafb',
  marginTop: '10px',
});

export const Button = styled.button(
  {
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '8px',
    color: '#282c34',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    textDecoration: 'none',
    margin: '0px 20px',
    padding: '12px 24px',
  },
  () => `${(props) => props.hidden && 'hidden'} :focus {
    border: none
    outline: none
  }`,
);

export const ParallaxMain = styled(Parallax)`
  min-height: 500px;
  background-color: ${(props) => props.theme.colors.gray4};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 110vh;

  .react-parallax-content {
    height: 100vh;
    width: 100vw;
  }
`;

export const ParallaxFeature = styled(Parallax)`
  min-height: 500px;
  background-color: ${(props) => props.theme.colors.gray4};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 500px;

  .react-parallax-content {
    height: 100vh;
    width: 100vw;
  }
`;

export const LazyLoadWrapper = styled(LazyLoad)`
  height: 120vh;
`;

export const LazyLoadImg = styled.img<LazyProps>(
  {
    minWidth: '100vw',
    objectFit: 'cover',
  },
  (props) => (props.lazy ? { height: '120vh' } : {}),
);

export const SectionFullWidth = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const SectionCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  flex-direction: column;
  padding: 1rem;
`;

export const SectionMainLogo = styled.img`
  height: 150px;
  max-width: 90vw;
  object-fit: contain;
  filter: drop-shadow(2px 2px 4px #73b);
`;

export const TextOverArtH1 = styled.h1`
  color: ${(props) => props.theme.colors.gray1};
  //-webkit-text-stroke: 1px ${(props) => props.theme.colors.brown};
  text-shadow: 2px 2px 2px ${(props) => props.theme.colors.brown},
    -1px -1px 0 ${(props) => props.theme.colors.brown},
    1px -1px 0 ${(props) => props.theme.colors.brown},
    -1px 1px 0 ${(props) => props.theme.colors.brown},
    1px 1px 0 ${(props) => props.theme.colors.brown};
`;

export const TextOverArtH3 = styled.h3`
  color: ${(props) => props.theme.colors.gray1};
  //-webkit-text-stroke: 1px ${(props) => props.theme.colors.brown};
  text-shadow: 2px 2px 2px ${(props) => props.theme.colors.brown},
    -1px -1px 0 ${(props) => props.theme.colors.brown},
    1px -1px 0 ${(props) => props.theme.colors.brown},
    -1px 1px 0 ${(props) => props.theme.colors.brown},
    1px 1px 0 ${(props) => props.theme.colors.brown};
`;

export const TextOverArt = styled.div`
  color: ${(props) => props.theme.colors.gray1};
  //-webkit-text-stroke: 1px ${(props) => props.theme.colors.brown};
  text-shadow: 2px 2px 2px ${(props) => props.theme.colors.brown},
    -1px -1px 0 ${(props) => props.theme.colors.brown},
    1px -1px 0 ${(props) => props.theme.colors.brown},
    -1px 1px 0 ${(props) => props.theme.colors.brown},
    1px 1px 0 ${(props) => props.theme.colors.brown};
`;

export const SectionDiv = styled.div`
  min-height: 500px;
  background-color: ${(props) => props.theme.colors.gray4};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const SectionBar = styled(SectionDiv)`
  background-color: ${(props) => props.theme.colors.gray1};
  min-height: unset;
`;

export const Results = styled.div<
  MobileProps & { full?: boolean; card?: boolean }
>(
  (props) => ({
    flex: '1 0 0%',
    width: '100%',
    height: props.full
      ? '100%'
      : props.mobile
      ? 'calc(100vh - 48px)'
      : 'calc(100vh - 72px)',
  }),
  (props) =>
    props.full
      ? props.card
        ? {
            display: 'flex',
            flexDirection: props.mobile ? 'column' : 'row',
            padding: props.mobile ? '16px' : '24px 0 40px',
          }
        : {}
      : {
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
        },
);

export const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagContainer = styled.div`
  height: 32px;
  background-color: ${(props) => props.theme.colors.darkGray};
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin: 4px 8px 4px 0;

  font-size: 14px;
  line-height: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #fff;
`;

export const ContentContainer = styled.div<MobileProps>(
  (props) => `
    width: ${props.mobile ? '100%' : '410px'};
    display: flex;
    flex-direction: column;
    padding: ${props.mobile ? '16px 0' : '0 32px'};
    border: 2px solid ${props.theme.colors.gray3};
    border-width: ${props.mobile ? '0' : '0 0 0 1px'};
    font-family: Montserrat;
  `,
);
