import { Contracts } from 'src/contracts/loadContracts';
import * as ActionTypes from '../ActionTypes';

export interface StoreContractsPayload {
  contracts: Contracts;
}

export const storeContractsData = (data: StoreContractsPayload) => ({
  type: ActionTypes.STORE_CONTRACTS,
  data,
});

export const getFarmData = () => ({
  type: ActionTypes.GET_FARM_DATA,
});

export const getFarmDataSuccess = (data) => ({
  type: ActionTypes.GET_FARM_DATA_SUCCESS,
  data,
});

export const getFarmDataError = (data) => ({
  type: ActionTypes.GET_FARM_DATA_ERROR,
  data,
});
