import erc20Abi from './erc20.json';
import farmAbi from './farm.json';
import governanceAbi from './governance.json';
import worldAbi from './world.json';

const abis = {
  erc20: erc20Abi,
  farm: farmAbi,
  governance: governanceAbi,
  world: worldAbi,
};

export default abis;
