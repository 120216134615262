/* eslint-disable @typescript-eslint/no-explicit-any */
// @flow
import { JsonRpcSigner, Provider } from '@ethersproject/providers';
import { Contracts } from 'src/contracts/loadContracts';
import {
  STORE_CONTRACTS,
  CONNECT_ACCOUNT_SUCCESS,
  CONNECT_ACCOUNT_ERROR,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';

export type ContractsState = {
  contracts: Contracts;
  provider?: Provider;
  signer?: JsonRpcSigner;
};

const INITIAL_STATE: ContractsState = {
  contracts: {} as Contracts,
};

const reducers = {
  [STORE_CONTRACTS]: (state, { data }) => {
    console.log('store contracts', data);
    return { ...state, contracts: data.contracts };
  },
  [CONNECT_ACCOUNT_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      provider: data.provider,
      signer: data.signer,
    };
  },
  [CONNECT_ACCOUNT_ERROR]: (state, { error }) => ({
    ...state,
    provider: undefined,
    signer: undefined,
  }),
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
