// External
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { ThemeProvider } from 'styled-components';

// Views
// import Home from '../views/Home';
import Marketplace from '../views/Marketplace';
import NFTSingle from '../views/NFTSingle';
import { Header } from 'src/components/header';
import { Footer } from 'src/components/footer';
import Creators from 'src/views/Creators';
import Creator from 'src/views/Creator';
import SignUp from 'src/views/SignUp';
import Profile from 'src/views/Profile';
import { Colors } from 'src/constants/colors';
import StoryPage from 'src/components/cmsdriven';
import ScrollToTop from 'src/util/ScrollToTop';

export const AppRouter: React.FC = () => {
  return (
    <ThemeProvider theme={{ colors: Colors }}>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" component={StoryPage} />
        <Route exact path="/market" component={Marketplace} />
        <Route exact path="/creators" component={Creators} />
        <Route exact path="/creator/:id" component={Creator} />
        <Route path="/nft/:id" component={NFTSingle} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/profile" component={Profile} />
        <Route path="*" exact={true} component={StoryPage} />
      </Switch>
      <Footer />
    </ThemeProvider>
  );
};
