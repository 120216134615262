import axios from 'axios';
import { contract } from 'src/contracts';
import { moralisOptions } from './moralisOptions';

// NOTE: We may be storing tokenIds as integers; they all parseInt fine.
export const getWorldsOwned = async (address: string) => {
  const worldsOwned: { [id: string]: number } = {};
  try {
    const axiosWorldsOwnedReturn = await axios.get(
      `https://deep-index.moralis.io/api/v2/${address}/nft/${contract.side.world}?chain=polygon&format=decimal&order=DESC`,
      moralisOptions,
    );

    if (
      axiosWorldsOwnedReturn.status === 200 &&
      !!axiosWorldsOwnedReturn.data
    ) {
      axiosWorldsOwnedReturn.data.map((owned) => {
        worldsOwned[owned.token_id] = owned.amount;
      });
      console.log('worlds owned:', worldsOwned);
    } else {
      console.log(`failed to get worlds owned for ${address}`);
    }
  } catch (err) {
    console.error('failed to getWorldsOwned', err);
  }

  return worldsOwned;
};
