import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { GenesisPadding } from '../cmsdriven';

type WrapperProps = SpaceProps & ColorProps;

export const Wrapper = styled.div<WrapperProps>(
  () => ({
    background: '#6d6d6d',
  }),
  space,
  color,
);

export const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;

  h1 {
    color: #cccccc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const SocialSection = styled.div`
  width: 168px;
  @media (max-width: 576px) {
    width: 45%;
  }
`;

export const SocialSectionRight = styled(SocialSection)`
  h1 {
    text-align: left;
  }

  display: flex;
  justify-content: flex-end;
`;

export const SocialSectionInner = styled.div`
  margin-right: 0;
`;

export const Links = styled.div`
  position: relative;
  margin-top: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;

  p {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #cccccc;
  }
`;

export const Link = styled.a`
  font-family: Montserrat;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: underline;

  color: #cccccc;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const LinkContent = styled.div`
  display: flex;
  color: #cccccc;

  span {
    margin: 0 4px;
  }
`;

export const ContactSection = styled.div`
  background: #cccccc;
  padding: 36px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
  }
`;

export const StayUpToDate = styled.div`
  font-family: Montserrat;
  width: 50%;
  padding: 4px;

  @media (max-width: 576px) {
    width: 100%;
  }

  h1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;

    color: #434343;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #6d6d6d;
  }
`;

export const EmailSignUp = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;

  @media (max-width: 576px) {
    width: 100%;
    margin-top: 16px;
  }

  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #434343;
    padding: 8px 13px;
    max-width: 450px;

    font-family: Montserrat;
    font-size: 16px;
    line-height: 24px;
    color: #434343;
  }

  button {
    margin-top: 16px;
    background: #25a4d6;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    max-width: 280px;
    width: 90%;
    padding: 8px 0;
    text-transform: uppercase;

    color: #ffffff;
  }
`;
