import { call, put, takeLatest } from 'redux-saga/effects';
import { getTokenPrice } from 'src/calls';
import { getTotalStaked } from 'src/calls/getTotalStaked';
import { contract } from 'src/contracts';

import { GET_CURRENT_PRICES, GET_TOTAL_STAKES } from '../ActionTypes';
import * as CreateFeaturedActions from './Actions';

export function* getErc20PricesSaga() {
  try {
    const game = yield call(getTokenPrice, contract.side.game);
    const genesis = yield call(getTokenPrice, contract.side.genesis);
    // const governance = yield call(getTokenPrice, contract.side.governance);
    const quick = yield call(getTokenPrice, contract.side.quick);
    const eth = yield call(getTokenPrice, contract.side.weth);
    const matic = yield call(getTokenPrice, contract.side.wmatic);
    const prices = {
      game,
      genesis,
      // governance,
      quick,
      eth,
      matic,
      usdt: 1,
      usdc: 1,
    };
    yield put(CreateFeaturedActions.getPricesSuccess(prices));
  } catch (e) {
    yield put(CreateFeaturedActions.getPricesError(e));
  }
}

function* getTotalStakesSaga() {
  try {
    const totalStaked = yield call(getTotalStaked);
    yield put(CreateFeaturedActions.getTotalStakesSuccess(totalStaked));
  } catch (e) {
    yield put(CreateFeaturedActions.getTotalStakesError(e));
  }
}

export default function* () {
  yield takeLatest(GET_CURRENT_PRICES, getErc20PricesSaga);
  yield takeLatest(GET_TOTAL_STAKES, getTotalStakesSaga);
}
