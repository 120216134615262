import axios from 'axios';
import { contract } from 'src/contracts';
import { ContractBalances } from 'src/redux/Account/Reducer';
import { moralisOptions } from './moralisOptions';

export const getContractBalances = async (): Promise<ContractBalances> => {
  const balances = { farm: {}, governance: {} };
  console.log('getContractBalances: Farm and Governance');
  try {
    const farmReturn = await axios.get(
      `https://deep-index.moralis.io/api/v2/${contract.side.farm}/erc20?chain=polygon`,
      moralisOptions,
    );

    console.log(farmReturn);
    if (farmReturn.status === 200 && !!farmReturn.data) {
      farmReturn.data.map((bal) => {
        balances.farm[bal.token_address] = bal;
      });
    } else {
      console.log(`failed to get balances for ${contract.side.farm}`);
    }

    const governanceReturn = await axios.get(
      `https://deep-index.moralis.io/api/v2/${contract.side.governance}/erc20?chain=polygon`,
      moralisOptions,
    );

    if (governanceReturn.status === 200 && governanceReturn.data) {
      governanceReturn.data.map((bal) => {
        balances.governance[bal.token_address] = bal;
      });
    } else {
      console.log(`failed to get balances for ${contract.side.governance}`);
    }
  } catch (err) {
    console.error('failed to getContractBalances', err);
  }
  console.log(balances);
  return balances;
};
