import React from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import {
  PageSectionStoryblok,
  StoryblokComponentType,
  StoryblokDesktopLayout,
} from 'src/typings';
import { parseRichText } from 'src/util';
import { ArtCutout, CutoutLocation } from '../';
import { CtaButton } from 'src/components/buttons';
import { Title, SiteCtaButtonStyle } from '../style';
import {
  PageSectionArtBoxStyle,
  PageSectionArtStyle,
  PageSectionParagraphStyle,
  PageSectionStyle,
  PageSectionTextStyle,
} from './style';

interface PageSectionProps {
  data: PageSectionStoryblok;
  nextSection: StoryblokComponentType;
  offset?: number;
  slug?: string;
}

export function PageSection(props: PageSectionProps) {
  const { isMobile } = useWindowSize();
  const { data, nextSection, offset, slug } = props;

  const isRightLayout = data.desktop_layout === StoryblokDesktopLayout.right;
  let location = isRightLayout
    ? CutoutLocation.right
    : data.desktop_layout === StoryblokDesktopLayout.left
    ? CutoutLocation.left
    : CutoutLocation.bottom;
  location = isMobile ? CutoutLocation.bottom : location;

  const overlay = ArtCutout(location, isMobile);

  const overlay2 = ArtCutout(CutoutLocation.top, isMobile);

  let overlay2Visible = location === CutoutLocation.bottom;
  if (slug === 'home') {
    if (isMobile) {
      overlay2Visible = true;
    } else {
      overlay2Visible = false;
    }
  } else {
    if (isMobile) {
      overlay2Visible = true;
    } else {
      overlay2Visible = overlay2Visible && offset !== 0;
    }
  }

  const isFullWidth =
    isMobile || data.desktop_layout === StoryblokDesktopLayout.full;

  const imageComponent = data.image ? (
    <PageSectionArtBoxStyle
      layout={data.desktop_layout}
      isFullWidth={isFullWidth}
    >
      <PageSectionArtStyle
        src={data.image.filename}
        alt={data.image.alt}
        layout={data.desktop_layout}
      />
      {overlay}
      {overlay2Visible && overlay2}
    </PageSectionArtBoxStyle>
  ) : (
    <div />
  );

  const contentSection = (
    <PageSectionTextStyle
      layout={data.desktop_layout}
      isFullWidth={isFullWidth}
      hasCta={data?.cta?.length > 0}
    >
      {data.title && <Title>{data.title}</Title>}
      {data.text_area && (
        <PageSectionParagraphStyle
          layout={data.desktop_layout}
          isFullWidth={isFullWidth}
        >
          {parseRichText(data.text_area)}
        </PageSectionParagraphStyle>
      )}

      {data.cta && data.cta.length > 0
        ? data.cta.map((button) => (
            <SiteCtaButtonStyle key={button._uid}>
              <CtaButton data={button} />
            </SiteCtaButtonStyle>
          ))
        : nextSection == StoryblokComponentType.PageSection && (
            <SiteCtaButtonStyle />
          )}
    </PageSectionTextStyle>
  );

  return (
    <PageSectionStyle
      className={`SiteHeader ${data.desktop_layout}`}
      key={data._uid}
      layout={data.desktop_layout}
    >
      {!isMobile && isRightLayout && contentSection}
      {imageComponent}
      {(isMobile || !isRightLayout) && contentSection}
    </PageSectionStyle>
  );
}
