import React from 'react';
import { WorldGridStoryblok } from 'src/typings';
import { CtaButton } from 'src/components/buttons';

interface WorldGridProps {
  data: WorldGridStoryblok;
}

export function WorldGrid(props: WorldGridProps) {
  const { data } = props;
  return (
    <div className={`WorldGrid ${data.layout}`} key={data._uid}>
      {data.title && <h1>{data.title}</h1>}
      <h1>H1 text here</h1>
      <h2>H2 text</h2>
      <h3>H3 text</h3>
      <h4>H4 text</h4>
      <h5>H5 text</h5>
      <h6>H6 text</h6>
      <p>World Grid Goes Here</p>
      <p>Filter: {data.filter}</p>
      <p>Layout: {data.layout}</p>
      <p>Source: {data.source}</p>

      {data.cta &&
        data.cta.map((button) => <CtaButton data={button} key={button._uid} />)}
    </div>
  );
}
