import { StoryStoryblok } from 'src/typings';
import * as ActionTypes from '../ActionTypes';

export const createStoryData = () => ({
  type: ActionTypes.GET_STORIES,
});

export const createStoryDataSuccess = (data: StoryStoryblok[]) => ({
  type: ActionTypes.GET_STORIES_SUCCESS,
  data,
});

export const createStoryDataError = (error) => ({
  type: ActionTypes.GET_STORIES_ERROR,
  error,
});

export const removeStoriesData = () => ({});
