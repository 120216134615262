import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CustomDropDown } from 'src/components/customDropDown';

import DownArrowImg from 'src/img/down_arrow.svg';

const DownImage = styled.img({
  width: '15px',
  height: '15px',
  margin: '0 5px',
  objectFit: 'contain',
  objectPosition: 'center',
});

const FieldTitle = styled.div`
  font-size: 16px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.darkGray};
  font-weight: 600;
`;

interface InputContainerProps {
  half?: boolean;
  dropDown?: boolean;
}

const InputContainer = styled.div<InputContainerProps>(
  (props) => `
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    flex: ${props.half ? '1' : 'unset'};
    margin-bottom: ${props.half ? '0' : '16px'};
    height: 40px;
    padding: ${props.dropDown ? '8px 14px 8px 16px' : '0'};
    position: relative;
  `,
);

const DropDownTitle = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  font-weight: 500;
  color: ${(props) => props.theme.colors.gray6};
`;

const DropdownContainer = styled.div<{
  marginRight?: boolean;
  marginLeft?: boolean;
}>(
  (props) => `
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 ${props.marginRight ? '10px' : '0'} 0 ${props.marginLeft ? '10px' : '0'
    };
  `,
);

export const DropdownFilter = ({
  style = {},
  toggleVisible,
  visible = false,
  title = '',
  placeholder = '',
  dropUp = false,
  half = false,
}) => {
  return (
    <DropdownContainer {...style}>
      {title.length > 0 && <FieldTitle>{title}</FieldTitle>}
      <InputContainer dropDown half={half}>
        <DropDownTitle onClick={() => toggleVisible(!visible)}>
          {placeholder}
        </DropDownTitle>
        <DownImage
          src={DownArrowImg}
          onClick={() => toggleVisible(!visible)}
        ></DownImage>
        {visible && (
          <CustomDropDown
            top={dropUp ? '-200px' : '40px'}
            dropUp={dropUp}
            height={'200px'}
            data={[
              'Option1',
              'Option2',
              'Option3',
              'Option4',
              'Option5',
              'Option6',
              'Option7',
              'Option8',
              'Option9',
              'Option10',
            ]}
            onToggle={() => toggleVisible(!visible)}
          ></CustomDropDown>
        )}
      </InputContainer>
    </DropdownContainer>
  );
};

DropdownFilter.propTypes = {
  style: PropTypes.object,
  toggleVisible: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  dropUp: PropTypes.bool,
  half: PropTypes.bool,
};
