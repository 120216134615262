import { Richtext } from 'storyblok-js-client';

export enum StoryblokComponentType {
  None = 'none',
  ActionButton = 'ActionButton',
  NavButton = 'NavButton',
  EmailSignup = 'EmailSignup',
  Page = 'Page',
  SiteHeader = 'SiteHeader',
  PageSection = 'PageSection',
  FunctionalSection = 'FunctionalSection',
  Post = 'Post',
  StatTable = 'StatTable',
  WorldGrid = 'WorldGrid',
  InfoPanel = 'InfoPanel',
}

export enum ButtonType {
  purchaseCurrencySelect = 'purchaseCurrencySelect',
  purchase = 'purchase',
  primary = 'primary',
  secondary = 'secondary',
  information = 'information',
}

export enum StoryblokEmailSignupType {
  banner = 'banner',
  popup = 'popup',
}

export enum StoryblokDesktopLayout {
  full = 'full',
  left = 'left',
  right = 'right',
}

export enum StoryblokStatTableRow {
  worldStakes = 'worldStakes',
  genesisMined = 'genesisMined',
  genesisBalance = 'genesisBalance',
  apr = 'apr',
  votesOwned = 'votesOwned',
  genesisStaked = 'genesisStaked',
  genesisEarned = 'genesisEarned',
  genesisToCollect = 'genesisToCollect',
}

export enum StoryblokWorldGridLayout {
  carousel = 'carousel',
  smallGrid = 'smallGrid',
  mediumGrid = 'mediumGrid',
  largeGrid = 'largeGrid',
}

export enum StoryblokWorldGridData {
  all = 'all',
  myWorlds = 'myWorlds',
  upcoming = 'upcoming',
  featured = 'featured',
}

export enum StoryblokLinkType {
  url = 'url',
  story = 'story',
}

export interface NavButtonStoryblok extends StoryblokComponent {
  text?: string;
  type: ButtonType;
  link: StoryblokLink;
  component: StoryblokComponentType.NavButton;
}

export interface ActionButtonStoryblok extends StoryblokComponent {
  text?: string;
  type: ButtonType;
  action: string;
  component: StoryblokComponentType.ActionButton;
}

export interface EmailSignupStoryblok extends StoryblokComponent {
  title?: string;
  text?: string;
  cta: StoryblokButtons[];
  type: StoryblokEmailSignupType;
  component: StoryblokComponentType.EmailSignup;
}

export interface PageStoryblok extends StoryblokComponent {
  body?: StoryblokPageEntry[];
  component: StoryblokComponentType.Page;
}

export interface SiteHeaderStoryblok extends StoryblokComponent {
  title?: string;
  suheader?: string;
  cta: StoryblokButtons[];
  image: StoryblokImage;
  component: StoryblokComponentType.SiteHeader;
}

export interface PageSectionStoryblok extends StoryblokComponent {
  image: StoryblokImage;
  title: string;
  text_area: Richtext;
  cta?: StoryblokButtons[];
  desktop_layout: StoryblokDesktopLayout;
  component: StoryblokComponentType.PageSection;
}

export interface FunctionalSectionStoryblok extends StoryblokComponent {
  type: FunctionalSectionType;
  component: StoryblokComponentType.FunctionalSection;
}

export interface PostStoryblok extends StoryblokComponent {
  title: string;
  image?: string;
  intro?: string;
  author?: string;
  component: StoryblokComponentType.Post;
}

export interface StatTableStoryblok extends StoryblokComponent {
  title?: string;
  rows: StoryblokStatTableRow[];
  cta: StoryblokButtons[];
  component: StoryblokComponentType.StatTable;
}

export interface WorldGridStoryblok extends StoryblokComponent {
  title?: string;
  filter?: boolean;
  layout: StoryblokWorldGridLayout;
  source: StoryblokWorldGridData;
  cta?: StoryblokButtons[];
  component: StoryblokComponentType.WorldGrid;
}

export interface StoryStoryblok {
  content: {
    body: StoryblokPageEntry[];
    component: string;
  };
  full_slug: string;
  lang: string;
  name: string;
  slug: string;
  tag_list: string[];
  first_published_at: Date;
  id: number;
  parent_id: number;
}

interface StoryblokLink {
  cached_url?: string;
  linktype?: StoryblokLinkType;
}

interface StoryblokDimension {
  entry_value: string;
  id: number;
  name: string;
}

export interface StoryblokDatasource {
  dimensions: StoryblokDimension[];
  name: string;
  slug: string;
  // FIXME - where is the actual data?
}

export interface StoryblokImage {
  alt: string;
  filename: string;
}

export enum FunctionalSectionType {
  Farming = 'Farming',
  Governance = 'GovernanceStaking',
  World = 'WorldMining',
}

interface StoryblokComponent {
  _uid: string;
  component: StoryblokComponentType;
}

export type StoryblokButtons = ActionButtonStoryblok | NavButtonStoryblok;

export type StoryblokPageEntry =
  | WorldGridStoryblok
  | StatTableStoryblok
  | PageSectionStoryblok
  | SiteHeaderStoryblok
  | FunctionalSectionStoryblok;
