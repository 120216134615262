import React from 'react';
import { Modal } from 'rsuite';
import { ModalBase, ModalProps } from '.';

export function WrongNetworkModal(props: ModalProps) {
  const { visible, show } = props;

  function onShowHide(isShowing: boolean) {
    show(isShowing);
  }
  const children = (
    <>
      <Modal.Header>
        <h3>Wrong Network</h3>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please open you Metamask and switch to the &quot;Polygon&quot; network
        </p>
      </Modal.Body>
    </>
  );

  return (
    <ModalBase visible={visible} show={onShowHide}>
      {children}
    </ModalBase>
  );
}
