import React, { useState } from 'react';
import styled from 'styled-components';
import LinesEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import BgImg from '../../../img/splash_bg.png';
import LogoImg from '../../../img/game_logo.svg';
import ShareImg from '../../../img/share.svg';

const HeaderBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BgImage = styled.img`
  width: 100%;
  height: 222px;
  object-fit: cover;
  object-position: center;
`;

const LogoImage = styled.img`
  width: 128px;
  height: 128px;
  position: absolute;
  top: 134px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  object-position: center;
`;

const OutContainer = styled.div`
  font-family: Montserrat;
  font-style: normal;
  color: #000000;

  display: flex;
  flex-direction: row;
  padding-left: 100px;
  margin: 40px 100px 40px 100px;
`;

const InnerContainer = styled.div<{ right?: boolean }>(
  () => `
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  (props) => `
    align-items: ${props.right ? 'flex-end' : 'flex-start'}
  `,
);

const InnerTitle = styled.div`
  font-size: 20px;
  line-height: 48px;
  font-weight: 600;
`;

const ShareButton = styled.img`
  width: 32px;
  height: 32px;
  margin: 8px 0;
  cursor: pointer;
  object-fit: contain;
  object-position: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;
`;

const CardItem = styled.div`
  background-color: #c4c4c4;
  display: flex;
  flex-direction: column;
  height: 85px;
  padding: 0 12px;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
`;

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  font-weight: 500;
  text-align: center;
`;

const CardDescription = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 14px;
  color: #333333;
  font-weight: 400;
  text-align: center;
`;

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const CreatorHeader = (props) => {
  const [numLine, setNumLine] = useState(4);

  const descriptionText =
    'A cargo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. oo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. You’ll find specialized types of cargo vessels include port ships, containers, soso on A cargo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. oo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. You’ll find specialized types of cargo vessels include port ships, containers, soso on';

  return (
    <HeaderBackground>
      <BgImage src={BgImg} />
      <LogoImage src={LogoImg} />
      <OutContainer>
        <InnerContainer>
          <InnerTitle>Project Name</InnerTitle>
          <ResponsiveEllipsis
            onClick={() => setNumLine(numLine === 4 ? 1000 : 4)}
            unsafeHTML={`<span style="font-size: 17px; line-height: 24px; color: #000; font-weight: 500;">${descriptionText}</span>`}
            maxLine={numLine}
            ellipsisHTML={`<span>...<p style="color:#3498db; cursor: pointer;"><u>Read More</u></p></span>`}
            basedOn="letters"
          />
        </InnerContainer>
        <InnerContainer right>
          <ShareButton src={ShareImg} />
          <CardContainer>
            <CardItem>
              <CardTitle>3,754</CardTitle>
              <CardDescription>Sold</CardDescription>
            </CardItem>
            <CardItem>
              <CardTitle>1,234</CardTitle>
              <CardDescription>For Sale</CardDescription>
            </CardItem>
            <CardItem>
              <CardTitle>45,000 G</CardTitle>
              <CardDescription>Biggest Sale</CardDescription>
            </CardItem>
            <CardItem>
              <CardTitle>13M G</CardTitle>
              <CardDescription>Total Sales</CardDescription>
            </CardItem>
          </CardContainer>
        </InnerContainer>
      </OutContainer>
    </HeaderBackground>
  );
};
