import { contract } from 'src/contracts';
import { formatUnits } from 'ethers/lib/utils';
import BN from 'bn.js';
import { ContractBalances } from 'src/redux/Account/Reducer';
import { ERC20Prices } from 'src/redux/ERC20/Reducer';

export interface FarmApr {
  apr: string;
  gameLocked: number;
  gamePrice: number;
  genesisPerDay: number;
  genesisPrice: number;
}

export default function calculateFarmApr(
  contractBalances: ContractBalances,
  prices: ERC20Prices,
) {
  const farmApr: FarmApr = {} as FarmApr;
  farmApr.gamePrice = prices.game || 1;
  farmApr.genesisPrice = prices.genesis || 1;

  let farmTotalLocked =
    contractBalances.farm[contract.side.game]?.balance || '1';
  if (farmTotalLocked == '1') {
    console.log('testing farm balance as 12345678');
    farmTotalLocked = '12345678000000000000000000';
  }

  farmApr.gameLocked = Math.floor(parseFloat(formatUnits(farmTotalLocked, 18)));

  const oneMonthFuture = 24 * 60 * 60 * 30;
  console.log('testing emission calculation one month in the future');

  const now = Date.now().valueOf() / 1000;
  const genesisPerDay = calculateFarmEmission(
    now + oneMonthFuture + 24 * 60 * 60,
  )
    .sub(calculateFarmEmission(now + oneMonthFuture))
    .toString();

  farmApr.genesisPerDay = Math.floor(
    parseFloat(formatUnits(genesisPerDay, 18)),
  );

  farmApr.apr = (
    (365 * 100 * farmApr.genesisPerDay * farmApr.genesisPrice) /
    farmApr.gameLocked /
    farmApr.gamePrice
  ).toFixed(2);
  return farmApr;
}
const eps = new BN('1584436925410000000');
const oneYear = new BN('31556952');

export function calculateFarmEmission(timestamp: number): BN {
  const startTime = 1633071600;
  if (timestamp <= startTime) {
    return new BN('0');
  } else {
    const time = new BN(timestamp - startTime);
    const emitted = time.mul(eps).mul(oneYear).div(oneYear.add(time));
    return emitted;
  }
}
