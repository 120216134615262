// 1. Require the Storyblok client
import Storyblok, { Richtext } from 'storyblok-js-client';
import { StoryStoryblok } from 'src/typings';
import HTMLReactParser from 'html-react-parser';

// 2. Initialize the client with the preview token
// from your space dashboard at https://app.storyblok.com
export const storyblok = new Storyblok({
  accessToken: 'ywchpoNLI6flTzyGRrrpXQtt', // PREVIEW
  // accessToken: 'pmps5r8Ixa7YQU95lqSGLQtt', // public
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

const getData = async () => {
  const stories = await storyblok.get('cdn/stories', {
    version: 'draft',
  });
  console.log('stories', stories);
  const tags = await storyblok.getAll('cdn/tags');
  console.log('tags', tags);
  const datasources = await storyblok.getAll('cdn/datasources');
  console.log('datasources', datasources);
  const links = await storyblok.getAll('cdn/links');
  console.log('links', links);
};

getData();

export const parseRichText = (data: Richtext) => {
  return HTMLReactParser(storyblok.richTextResolver.render(data));
};

export const getStory = async (slug: string): Promise<StoryStoryblok> => {
  const result = await storyblok.get(`cdn/stories/${slug}`, {
    version: 'draft',
  });
  console.log(`got storyblok story for: cdn/stories/${slug}`, result);
  return result.data;
};

export const getAllStories = async (): Promise<StoryStoryblok[]> => {
  const result = await storyblok.get(`cdn/stories`, {
    version: 'draft',
  });
  console.log(`got storyblok stories for: cdn/stories`, result);
  return result.data.stories;
};
