import React, { useState, useEffect, useContext } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

// images
import LogoImg from '../../img/GenesisLogo_256.png';
import AcctImg from '../../img/acct_icon.svg';
import ArrowImg from '../../img/tri_arrow.png';
import HamburgerImg from '../../img/hamburger.svg';
import CloseBlackImg from '../../img/close_black.svg';
import AcctGrayImg from '../../img/acct_gray_icon.svg';

// hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// components
import {
  Wrapper,
  MobileMenu,
  MenuOption,
  Row,
  LogoImage,
  ButtonContainer,
  DesktopButton,
  MarkerImg,
  AccountIcon,
  BlurDiv,
  MenuIcon,
  MenuBody,
  HeaderBackground,
} from './style';
import { FilterContext, MetamaskContext } from 'src/contexts';
import { Routes, RouteTitles } from 'src/constants/routes';
import { SocialLinks } from 'src/constants/social';
import { CtaButton } from '../buttons';
import {
  ButtonType,
  StoryblokComponentType,
  StoryblokLinkType,
} from 'src/typings';

export const Header = () => {
  const lastItemIndex = Object.keys(Routes).length;
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(-1);
  const { isMobile } = useWindowSize();
  const { walletAddr } = useContext(MetamaskContext);
  const { visible: filterVisible, toggleVisible: toggleFilterVisible } =
    useContext(FilterContext);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      if (!filterVisible) {
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    if (activeTab === lastItemIndex) {
      setActiveTab(0);
      setMenuOpen(false);
    } else {
      setActiveTab(
        Object.values(Routes).indexOf(location.pathname as Routes) || 0,
      );
    }
  }, [location.pathname]);

  const handleMenuClick = (event, index, url) => {
    event.stopPropagation();
    if (url === 'discord') {
      window.open(SocialLinks.DISCORD);
    } else {
      history.push(url);
      setMenuOpen(false);
    }
  };

  return (
    <Wrapper mobile={isMobile} style={{ overflow: 'hidden' }}>
      <HeaderBackground />
      <MobileMenu expanded={menuOpen}>
        <MenuBody>
          {Object.keys(Routes).map((route, index) => {
            return (
              <MenuOption
                key={`menu-${index}`}
                first={index === 0}
                isActive={false}
                isMobile={isMobile}
                onClick={(e) => handleMenuClick(e, index, route)}
              >
                {RouteTitles[Routes[route]]}
              </MenuOption>
            );
          })}
          <MenuOption
            key={`menu-buy-worlds`}
            isActive={false}
            isMobile={isMobile}
            onClick={(e) =>
              handleMenuClick(
                e,
                Object.keys(Routes).indexOf('worlds'),
                Routes.worlds,
              )
            }
          >
            <CtaButton
              data={{
                _uid: '5',
                text: 'Buy Worlds',
                type: ButtonType.purchase,
                link: {
                  linktype: StoryblokLinkType.story,
                },
                component: StoryblokComponentType.NavButton,
              }}
            />
          </MenuOption>
          <MenuOption
            key={`menu-discord`}
            isActive={false}
            isMobile={isMobile}
            onClick={(e) => handleMenuClick(e, 0, 'discord')}
          >
            Join Our Discord
          </MenuOption>
        </MenuBody>
        <BlurDiv
          onClick={() => {
            setMenuOpen(false);
          }}
        ></BlurDiv>
      </MobileMenu>
      <Row
        mobile={isMobile}
        flex={1}
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        <LogoImage
          mobile={isMobile}
          src={LogoImg}
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(false);
            isMobile && setActiveTab(-1);
            history.push('/');
          }}
        />
        <ButtonContainer>
          {Object.keys(Routes).map((route, index) => {
            return (
              index > 0 && (
                <DesktopButton
                  key={`desktop-button-${index}`}
                  mobile={isMobile}
                  isActive={activeTab === index}
                  onClick={(e) => handleMenuClick(e, index, route)}
                  isButton={false}
                >
                  {RouteTitles[Routes[route]]}
                  <MarkerImg src={ArrowImg} />
                </DesktopButton>
              )
            );
          })}
        </ButtonContainer>
        <DesktopButton
          mobile
          isRight
          isButton={true}
          isActive={activeTab === lastItemIndex}
          onClick={(e) => {
            e.stopPropagation();
            setActiveTab(lastItemIndex);
            setMenuOpen(false);
            toggleFilterVisible(false);
            // if (walletAddr === '') {
            //   history.push('/wallet-connector');
            // } else {
            history.push('/rewards');
            // }
          }}
        >
          <AccountIcon
            isActive={activeTab === lastItemIndex}
            isMobile={isMobile}
            src={/*activeTab === lastItemIndex ? AcctGrayImg : */ AcctImg}
          />
        </DesktopButton>
        <DesktopButton
          menuOpen={menuOpen}
          isButton={true}
          isActive={false}
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon
            menuOpen={menuOpen}
            src={menuOpen ? CloseBlackImg : HamburgerImg}
          />
        </DesktopButton>
      </Row>
    </Wrapper>
  );
};
