import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { WindowSize } from '../constants';

type WrapperProps = SpaceProps & ColorProps;

export const SiteHeaderStyle = styled.div<WrapperProps & { imageUrl: string }>(
  (props) => ({
    position: 'relative',
    width: '100vw',
    paddingTop: '20%',
    height: '460px',
    [WindowSize.desktop]: {
      height: '500px',
      paddingTop: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    textShadow: '3px 0 5px #000000, -2px 0 5px #000000',
    backgroundImage: `url(${props.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }),
  space,
  color,
);
