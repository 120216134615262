import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div({
  padding: '16px 20px',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'auto',
  justifyContent: 'center',
});

const Item = styled.div({
  background: '#FFFFFF',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '12px',
  display: 'block',
  width: '288px',
  height: '468px',
  cursor: 'pointer',
});

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  position: relative;
  height: 180px;
`;

const ImageContainer = styled.div({
  backgroundColor: '#96B3BC',
  width: '100%',
});

const Image = styled.img({
  width: '100%',
  aspectRatio: '1',
  objectFit: 'contain',
});

const ItemDescription = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 12px;
`;

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ItemPrice = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.colors.red};
  white-space: pre-wrap;
`;

const ItemIndex = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: #000000;
  position: absolute;
  right: 12px;
  bottom: 12px;
`;

export const ResultsBody = () => {
  const history = useHistory();

  const data = [
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/OGDNkQIWqH2flzAey2w5OICZOfEsF51SkA3n5L_BMRqe4nTxlI5DEnrUWQGRw4vtbVK-4WG6C1WPw3VhXpDk97_HIhRCd6yyUnonbg=s341',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/XlLL3euFkGYj4V8uqiWZ0rnOdYG3fRt7Yaz1EA-7tYRejWq1-oelj9TmUe4z6VNCVO0OZc_IJ8NOjarIWKZ2Qj8Esz9B3gAE-kJlqQ=s341',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/hImYwj_jogK1fJE5I6nIm-o0qR8L_H4u5MyvKTax8gPCW_80gNEcDwxcIa_nm3EOzZvwtkamRKXqyXt4JBLt9XPLOzk_XuekXGkXpw=s341',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
  ];
  return (
    <Wrapper>
      {data.map((d, i) => (
        <Item key={i} onClick={() => history.push('/nft/123')}>
          <ImageContainer>
            <Image src={d.image} />
          </ImageContainer>
          <ItemInfo>
            <ItemDescription>{`Creator's name`}</ItemDescription>
            <ItemTitle>
              Name of this NFTName of this NFTName of this NFTName of this NFT
            </ItemTitle>
            <ItemPrice>{'9999\nGAME'}</ItemPrice>
            <ItemIndex>1 / 100</ItemIndex>
          </ItemInfo>
        </Item>
      ))}
    </Wrapper>
  );
};
