import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  GET_ERC20_BALANCE,
  GET_WORLD_BALANCE,
  SET_ADDRESS,
} from '../ActionTypes';
import * as GetBondedActions from './Actions';
import {
  getPolygonBalances,
  getMainBalances,
  getWorldsOwned,
  getAllowance,
} from 'src/calls';
import { AllBalances } from './Reducer';
import { getContractBalances } from 'src/calls/getContractBalances';
import { getAddress } from '../';

function* getErc20Balances() {
  try {
    console.log('getting ERC20 balances');
    const address = yield select(getAddress);
    if (!address) {
      throw 'No ethereum address found';
    }
    // address = '0x07FE2E1e843E73c955f1c176B4f20E7AC342d1d4';
    // console.log('SAGA GET BALANCES for', address);
    console.log('got address', address);

    const sideBalances = yield call(getPolygonBalances, address);
    const mainBalances = yield call(getMainBalances, address);
    const contractBalances = yield call(getContractBalances);

    const balances: AllBalances = {
      sideBalances: sideBalances,
      mainBalances: mainBalances,
      contractBalances: contractBalances,
    };
    console.log('got balances', balances);

    yield put(GetBondedActions.getErc20BalancesSuccess(balances));
  } catch (e) {
    yield put(GetBondedActions.getErc20BalancesError(e));
  }
}

function* getWorldNftBalances() {
  try {
    const address = yield select(getAddress);
    if (!address) {
      throw 'No ethereum address found';
    }
    // address = '0x07FE2E1e843E73c955f1c176B4f20E7AC342d1d4';
    // console.log('SAGA GET BALANCES for', address);

    const worldBalances = yield call(getWorldsOwned, address);

    yield put(GetBondedActions.getworldBalancesSuccess(worldBalances));
  } catch (e) {
    yield put(GetBondedActions.getWorldBalancesError(e));
  }
}

function* getAllowanceFor(token: string, spender: string) {
  try {
    const address = yield select(getAddress);
    if (!address) {
      throw 'No ethereum address found';
    }
    // address = '0x07FE2E1e843E73c955f1c176B4f20E7AC342d1d4';
    // console.log('SAGA GET BALANCES for', address);

    const allowance = yield call(getAllowance, address, token, spender);

    yield put(
      GetBondedActions.getAllowanceSuccess({ token, spender, allowance }),
    );
  } catch (e) {
    yield put(GetBondedActions.getAllowanceError(e));
  }
}

export function* getErc20BalancesSaga() {
  yield takeLatest(GET_ERC20_BALANCE, getErc20Balances);
}

export function* getWorldBalancesSaga() {
  yield takeLatest(GET_WORLD_BALANCE, getWorldNftBalances);
}

function* continueFromAddressSetting() {
  console.log('continuing from address setting');
  yield getErc20Balances();
  yield getWorldNftBalances();
}

export function* setAddressSaga() {
  yield takeLatest(SET_ADDRESS, continueFromAddressSetting);
}
