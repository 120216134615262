import React from 'react';
import { useHistory } from 'react-router';
import {
  NavButtonStoryblok,
  ActionButtonStoryblok,
  StoryblokButtons,
  StoryblokComponentType,
  StoryblokLinkType,
} from 'src/typings';
import { ButtonText, ButtonStyle } from '../style';

interface CtaButtonProps {
  data: StoryblokButtons;
  isDisabled?: boolean;
}

export function CtaButton(props: CtaButtonProps) {
  const { data, isDisabled } = props;
  const history = useHistory();

  switch (data.component) {
    case StoryblokComponentType.ActionButton:
      const action = (data as ActionButtonStoryblok).action;
      return (
        <ButtonStyle
          onClick={() => {
            console.log(`This button should take action: ${action}`);
          }}
          className={`actionButton`}
          variant={data.type}
          key={data._uid}
          isDisabled={isDisabled}
        >
          {data.text && <ButtonText>{data.text}</ButtonText>}
        </ButtonStyle>
      );
    case StoryblokComponentType.NavButton:
      const link = (data as NavButtonStoryblok).link;
      return (
        <ButtonStyle
          onClick={() => {
            if (!link?.cached_url) {
              console.log('failed to find link for button', data);
            } else if (link.linktype === StoryblokLinkType.url) {
              const newWindow = window.open(
                link.cached_url,
                '_blank',
                'noopener,noreferrer',
              );
              if (newWindow) {
                newWindow.opener = null;
              }
            } else if (link.linktype === StoryblokLinkType.story) {
              history.push(`/${link.cached_url}`);
            } else {
              console.log('failed to find link type for button', data);
            }
          }}
          className={`navButton`}
          variant={data.type}
          key={data._uid}
          isDisabled={isDisabled}
        >
          {data.text && <ButtonText>{data.text}</ButtonText>}
        </ButtonStyle>
      );
    default:
      console.log('Failed to parse component type for button', data);
      return <div />;
  }
}
