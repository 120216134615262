import React from 'react';
import styled from 'styled-components';
import { SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';

import { useWindowSize } from 'src/hooks/useWindowSize';
import SortBy from './SortBy';

const SortHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0px 32px',
  margin: '12px auto',
  maxWidth: 'calc(100vw)',
});

const Button = styled.div({
  alignItems: 'center',
  backgroundColor: 'rgb(50, 145, 233)',
  borderRadius: '25px',
  color: 'white',
  display: 'flex',
  fontSize: '16px',
  height: '40px',
  justifyContent: 'center',
  width: '292px',
  boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 20px',
});

const NFTStatus = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray6};
`;

export const ResultsHeader = (props) => {
  const { isMobile } = useWindowSize();
  const { onShowFilter } = props;

  const creatorOptions = ['Creator1', 'Creator2', 'Creator3', 'Creator4'];
  const creatorData = creatorOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const minPriceOptions = ['1', '2', '3', '4'];
  const minPriceData = minPriceOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const maxPriceOptions = ['100', '200', '300', '400'];
  const maxPriceData = maxPriceOptions.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <SortHeader>
      {isMobile ? (
        <Button onClick={onShowFilter}>Sort & Filter</Button>
      ) : (
        <>
          <SelectPicker
            data={creatorData}
            style={{
              height: 48,
              width: isMobile ? '100%' : 200,
              marginTop: 5,
              marginRight: '16px',
            }}
            placeholder="Filter: Creator Name"
          />
          <SelectPicker
            data={minPriceData}
            style={{
              height: 48,
              width: isMobile ? '100%' : 200,
              marginTop: 5,
              marginRight: '16px',
            }}
            placeholder="No Min Price"
          />
          <SelectPicker
            data={maxPriceData}
            style={{
              height: 48,
              width: isMobile ? '100%' : 200,
              marginTop: 5,
              marginRight: '32px',
            }}
            placeholder="No Max Price"
          />
          <NFTStatus>{'133 of 1567 NFTs'}</NFTStatus>
          <SortBy />
        </>
      )}
    </SortHeader>
  );
};

ResultsHeader.propTypes = {
  onShowFilter: PropTypes.func,
};
