import { Web3Provider } from '@ethersproject/providers';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Fortmatic from 'fortmatic';
import Portis from '@portis/web3';
import { Dispatch } from 'react';
import {
  connectAccountError,
  connectAccountSuccess,
  setAddress,
} from 'src/redux/Account/Actions';
import { loadContracts } from 'src/contracts/loadContracts';
import { storeContractsData } from 'src/redux/Contracts/Actions';

//To do:
/*
  What happens if metamask shows account X, but the one connected is account Y?
*/

const web3Modal: Web3Modal = new Web3Modal({
  network: 'matic',
  cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          137: 'https://polygon-rpc.com',
        },
      },
    },
    /*fortmatic: {
      package: Fortmatic,
      options: {
        // Genesis live key
        key: 'pk_live_9075E971E1AEB6BB',
      },
    },
    portis: {
      package: Portis,
      options: {
        id: '74dbaf56-8e0e-4a4b-bd53-6d02ce08ceab',
        registerPageByDefault: false,
      },
    },*/
  },
  disableInjectedProvider: false,
});

export async function connectCachedWallet(dispatch: Dispatch<any>) {
  if (web3Modal.cachedProvider) {
    connectWallet(dispatch);
  }
}

export async function connectWallet(dispatch: Dispatch<any>) {
  try {
    const provider = await web3Modal.connect();

    // this is where the magic happens
    const ethersProvider = new Web3Provider(provider, 'any');

    ethersProvider.on('network', (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork?.chainId === 137) {
        console.log(
          'switched networks away from Matic - reload as things stop working',
        );
        // window.location.reload();
      }
    });

    // if using react, you could then set this in the state. otherwise return it or whatever
    const signer = ethersProvider.getSigner();
    const address = await signer.getAddress();
    const providers = {
      provider: ethersProvider,
      signer: signer,
      address: address,
    };

    console.log('got address from signer', address);
    dispatch(connectAccountSuccess(providers));
    dispatch(setAddress(address));

    // Load and store contracts
    const contracts = loadContracts(signer);
    console.log('connectWallet store contracts');
    dispatch(storeContractsData({ contracts }));

    return providers;
  } catch (error) {
    console.log('connect account error', error);
    dispatch(connectAccountError(error));
    return undefined;
  }
}

export function disconnectWallet(dispatch: Dispatch<any>) {
  web3Modal.clearCachedProvider();
  dispatch(connectAccountError('disconnecting'));
  window.location.reload();
}
