import React, { useContext } from 'react';
import styled from 'styled-components';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { Main, MobileProps, Results } from 'src/components';
import { PageFrame } from 'src/components/page';

import { useWindowSize } from 'src/hooks/useWindowSize';

import { BackButton } from 'src/components/buttons/backButton';
import { Colors } from 'src/constants/colors';
import { MetamaskContext } from 'src/contexts';

const InnerContainer = styled.div<MobileProps>(
  (props) => `
    flex-direction: column;
    align-items: center;
    display: flex;
    flex: ${props.mobile ? '1' : 'unset'};
    padding: ${props.mobile ? '0 16px' : '0'};
  `,
);

const InnerTitle = styled.div<MobileProps>(
  (props) => `
    font-size: ${props.mobile ? '20px' : '32px'};
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    color: ${props.theme.colors.darkGray};
    margin-bottom: 20px;
  `,
);

const InnerSubTitle = styled.div<MobileProps & { bottom?: boolean }>(
  (props) => `
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: left;
    color: ${props.theme.colors.gray6};
    width: ${props.mobile ? '100%' : '340px'};
    word-wrap: none;
    margin: ${props.bottom ? '40px 0 8px' : '8px 0'};
  `,
);

const CancelButton = styled.div<MobileProps>(
  (props) => `
    border: 1px solid #01998C;
    width: ${props.mobile ? '100%' : '340px'};
    height: 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: ${props.mobile ? '16px' : '24px'};
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-top: ${props.mobile ? 'auto' : '60px'};
  `,
);

interface SubscribeButtonProps {
  active?: boolean;
}

const SubscribeButton = styled.div<SubscribeButtonProps>(
  (props) => `
    border: 1px solid #01998C;
    width: 100%;
    height: 40px;
    background-color: ${props.active ? '#01998C' : '#0000'};
    color: ${props.active ? '#FFF' : props.theme.colors.gray6};
    display: flex;
    text-align: center;
    cursor: pointer;
    font-size: ${props.active ? '20px' : '16px'};
    line-height: ${props.active ? '24px' : '20px'};
    font-weight: ${props.active ? '700' : '500'};
    justify-content: center;
    align-items: center;
    margin: ${props.active ? '0 0 0 5px' : '0 5px 0 0'};
  `,
);

const ButtonRow = styled.div<MobileProps>(
  (props) => `
    display: flex;
    width: ${props.mobile ? '100%' : '340px'};
    align-items: center;
    justify-content: space-between;
  `,
);

const InputField = styled.input<MobileProps>(
  (props) => `
    height: 40px;
    width: ${props.mobile ? '100%' : '340px'};
    background: #FFF;
    border: 1px solid #CACACA;
    outline: none;
    padding: 8px 16px;
    color: ${props.theme.colors.gray6};
    font-size: 16px;
    margin-bottom: 20px;
  `,
);

const AvatarContainer = styled.div`
  cursor: pointer;
  text-align: center;
  position: relative;
  height: 60px;
  width: 60px;
  z-index: 1;
  margin: 20px;
`;

const AvatarDiv = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.colors.gray3};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: -10;
`;

const Profile = () => {
  const { walletAddr } = useContext(MetamaskContext);
  const { isMobile } = useWindowSize();

  return (
    <PageFrame>
      <Main style={{ backgroundColor: Colors.gray2 }}>
        <Results mobile={isMobile}>
          <BackButton
            backUrl="/market"
            height="50px"
            padding="0 16px"
            border="none"
          />
          <InnerContainer mobile={isMobile}>
            <InnerTitle mobile={isMobile}>MY ACCOUNT</InnerTitle>
            <AvatarContainer>
              Upload Your Avatar
              <AvatarDiv></AvatarDiv>
            </AvatarContainer>
            <InputField
              mobile={isMobile}
              disabled
              placeholder="Username"
            ></InputField>
            <InputField
              mobile={isMobile}
              disabled
              placeholder="Email address"
            ></InputField>
            <InnerSubTitle mobile={isMobile}>
              {`Wallet: ${walletAddr}`}
            </InnerSubTitle>
            <InnerSubTitle mobile={isMobile}>
              My Collection (link?)
            </InnerSubTitle>
            <InnerSubTitle mobile={isMobile} bottom>
              Keep me informed of the latest Mynty news
            </InnerSubTitle>
            <ButtonRow mobile={isMobile}>
              <SubscribeButton>UNSUBSCRIBE</SubscribeButton>
              <SubscribeButton active>SUBSCRIBE</SubscribeButton>
            </ButtonRow>
            <CancelButton mobile={isMobile}>LOG OUT</CancelButton>
          </InnerContainer>
        </Results>
      </Main>
    </PageFrame>
  );
};

export default Profile;
