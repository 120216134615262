import React from 'react';
import styled from 'styled-components';

import { PageSection, SiteHeader, WorldGrid } from '../';
// StatTable = 'StatTable',
import {
  FunctionalSectionStoryblok,
  PageSectionStoryblok,
  StoryblokComponentType,
  StoryStoryblok,
} from 'src/typings';
import { FunctionalSection } from '../FunctionalSection';

const StoryComponent = styled.div`
  width: 100%;
  position: relative;
`;

export function Story(page: StoryStoryblok) {
  return (
    <StoryComponent>
      {page.content.body.map((section, i) => {
        switch (section.component) {
          case StoryblokComponentType.SiteHeader:
            return <SiteHeader data={section} key={section._uid} />;
          case StoryblokComponentType.FunctionalSection:
            return (
              <FunctionalSection
                type={(section as FunctionalSectionStoryblok).type}
                key={section._uid}
              />
            );
          case StoryblokComponentType.PageSection:
            const nextSection =
              i === page.content.body.length - 1
                ? StoryblokComponentType.None
                : page.content.body[i + 1].component;
            return (
              <PageSection
                data={section}
                nextSection={nextSection}
                key={section._uid}
                offset={i}
                slug={page.slug}
              />
            );
          case StoryblokComponentType.WorldGrid:
            return <WorldGrid data={section} key={section._uid} />;
          default:
            console.log(`didn't find Story section for`, section);
            return <p key={i}>Missing Section: {section.component}</p>;
        }
      })}
    </StoryComponent>
  );
}
