export const GET_SECRETS = 'GET_SECRETS';
export const GET_SECRETS_SUCCESS = 'GET_SECRETS_SUCCESS';
export const GET_SECRETS_ERROR = 'GET_SECRETS_ERROR';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

export const STORE_CONTRACTS = 'STORE_CONTRACTS';

export const GET_FARM_DATA = 'GET_FARM_DATA';
export const GET_FARM_DATA_SUCCESS = 'GET_FARM_DATA_SUCCESS';
export const GET_FARM_DATA_ERROR = 'GET_FARM_DATA_ERROR';

export const GET_CURRENT_PRICES = 'GET_CURRENT_PRICES';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_ERROR = 'GET_PRICES_ERROR';
export const GET_TOTAL_STAKES = 'GET_TOTAL_STAKES';
export const GET_TOTAL_STAKES_SUCCESS = 'GET_TOTAL_STAKES_SUCCESS';
export const GET_TOTAL_STAKES_ERROR = 'GET_TOTAL_STAKES_ERROR';

export const SET_ADDRESS = 'SET_ADDRESS';

export const GET_BONDED = 'GET_BONDED';
export const GET_BONDED_SUCCESS = 'GET_BONDED_SUCCESS';
export const GET_BONDED_SUPPLY_SUCCESS = 'GET_BONDED_SUPPLY_SUCCESS';
export const GET_BONDED_ERROR = 'GET_BONDED_ERROR';

export const GET_STORIES = 'GET_STORIES';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_ERROR = 'GET_STORIES_ERROR';

export const GET_ERC20_BALANCE = 'GET_ERC20_BALANCE';
export const GET_ERC20_BALANCE_SUCCESS = 'GET_ERC20_BALANCE_SUCCESS';
export const GET_ERC20_BALANCE_ERROR = 'GET_ERC20_BALANCE_ERROR';

export const GET_ALLOWANCE = 'GET_ALLOWANCE';
export const GET_ALLOWANCE_SUCCESS = 'GET_ALLOWANCE_SUCCESS';
export const GET_ALLOWANCE_ERROR = 'GET_ALLOWANCE_ERROR';

export const GET_WORLD_BALANCE = 'GET_WORLD_BALANCE';
export const GET_WORLD_BALANCE_SUCCESS = 'GET_WORLD_BALANCE_SUCCESS';
export const GET_WORLD_BALANCE_ERROR = 'GET_WORLD_BALANCE_ERROR';

export const CONNECT_ACCOUNT = 'CONNECT_ACCOUNT';
export const CONNECT_ACCOUNT_SUCCESS = 'CONNECT_ACCOUNT_SUCCESS';
export const CONNECT_ACCOUNT_ERROR = 'CONNECT_ACCOUNT_ERROR';
