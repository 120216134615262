import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { Main, Results } from 'src/components';
import { PageFrame } from 'src/components/page';
import { ResultsHeader } from './components/ResultsHeader';
import { ResultsBody } from './components/ResultsBody';

import { Colors } from 'src/constants/colors';

const MarketPlace = () => {
  const [showFilter, setShowFilter] = useState(true);

  return (
    <PageFrame>
      <Main style={{ backgroundColor: Colors.gray2 }}>
        <Results full>
          <ResultsHeader
            showFilter={showFilter}
            onShowFilter={() => setShowFilter(true)}
          />
          <ResultsBody />
        </Results>
      </Main>
    </PageFrame>
  );
};

export default MarketPlace;
