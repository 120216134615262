/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export const ACCOUNTS_KEY = 'accounts';
export const ALL_WORLDS_KEY = 'worlds';
export const ALL_LAND_KEY = 'land';
export const OPEN_SEA_KEY = 'openSea';
export const ALL_AUCTIONS_KEY = 'auctions';
export const ALL_USERS_KEY = 'users';
export const MANAGEMENT_KEY = 'mgmt';
export const REF_MARKETING = 'marketing';
export const REF_FAQ = 'FAQ';
export const REF_FEATURED = 'featured';
export const REF_WORLDS = 'worlds';
export const REF_SCHEDULED_SALES = 'scheduledsales';
export const REF_SCHEDULED_NFTS = 'schedulednfts';
export const REF_RECENT_NFT_SALES = 'recentlysold';
export const REF_NFTS = 'nfts';
export const ETH_USER = 'ethUser';
export const BONDED = 'bonded/tokens';
export const BONDED_SUPPLY = 'bonded/tokensupply';

interface FirebaseOptions {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

// Initialize Firebase
const config: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY_TESTNET,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_TESTNET,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL_TESTNET,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_TESTNET,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_TESTNET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_TESTNET,
  appId: process.env.REACT_APP_FIREBASE_APPID_TESTNET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_TESTNET,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();

const database = firebase.database();

//const userRef: firebase.database.Reference = databaseRef.child('users');
//const worldRef: firebase.database.Reference = databaseRef.child('world');
//const landRef: firebase.database.Reference = databaseRef.child('land');
//const openSeaRef: firebase.database.Reference = databaseRef.child('opensea');
//const accountsRef: firebase.database.Reference = databaseRef.child('accounts');

async function databaseRef(): Promise<firebase.database.Reference> {
  if (!dbRef) {
    // get the DB ref
    initialize();
  }
  return database.ref(dbRef);
}

const DATA_STORE_KEY = '1oDvDUvIiei5O-7i3DgmSt8Bx7RgfHNWE49USWs1ZhlE';
const dataStoreRef: firebase.database.Reference = database.ref(DATA_STORE_KEY);
const MYNTY_STORE_KEY = 'mynty';
const myntyStoreRef: firebase.database.Reference =
  database.ref(MYNTY_STORE_KEY);
const ethUserRef: firebase.database.Reference = database.ref(ETH_USER);
const bondedRef: firebase.database.Reference = database.ref(BONDED);
const bondedSupplyRef: firebase.database.Reference =
  database.ref(BONDED_SUPPLY);

async function userRef(): Promise<firebase.database.Reference> {
  return (await databaseRef()).child(ALL_USERS_KEY);
}
async function landRef(): Promise<firebase.database.Reference> {
  return (await databaseRef()).child(ALL_LAND_KEY);
}
async function worldRef(): Promise<firebase.database.Reference> {
  return (await databaseRef()).child(ALL_WORLDS_KEY);
}
async function openSeaRef(): Promise<firebase.database.Reference> {
  return (await databaseRef()).child(OPEN_SEA_KEY);
}
async function accountsRef(): Promise<firebase.database.Reference> {
  return (await databaseRef()).child(ACCOUNTS_KEY);
}
let dbRef = '';

const initialize = () => {
  dbRef = '1oDvDUvIiei5O-7i3DgmSt8Bx7RgfHNWE49USWs1ZhlE';
};

export const initializeFirebase = () => {
  initialize();
};

export {
  databaseRef,
  auth,
  dataStoreRef,
  userRef,
  worldRef,
  landRef,
  openSeaRef,
  accountsRef,
  myntyStoreRef,
  ethUserRef,
  bondedRef,
};
