import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'rsuite';
import { ModalBase, ModalProps } from '.';

export function ConnectingModal(props: ModalProps) {
  const { visible, show } = props;

  function onShowHide(isShowing: boolean) {
    show(isShowing);
  }

  const children = (
    <>
      <Modal.Header>
        <h3>Web3 Connection Required</h3>
      </Modal.Header>
      <Modal.Body>
        <p>
          You need to connect to Metamask to use the Genesis site. If you have
          Metamask installed, click the button again, then open your Metamask
          and connect your account.
        </p>
        <p>
          If you don&apos;t have Metamask, you can get it here:{' '}
          <a
            href="https://metamask.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            metamask.io
          </a>
        </p>
      </Modal.Body>
    </>
  );

  return (
    <ModalBase visible={visible} show={onShowHide}>
      {children}
    </ModalBase>
  );
}
