import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SelectPicker } from 'rsuite';
import { useWindowSize } from 'src/hooks/useWindowSize';
import SortBy from './SortBy';
import DownArrowImg from '../../../img/down_arrow.svg';
import UpArrowImg from '../../../img/up_arrow.svg';
import SearchImg from '../../../img/magnify.svg';
import { FilterContext } from 'src/contexts';
import { DropdownFilter } from 'src/components/dropdownFilter';
import { MobileProps } from 'src/components';

const SortHeader = styled.div<MobileProps>((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: props.mobile ? '0 16px' : '0 32px',
  margin: props.mobile ? '0 auto' : '12px auto',
  maxWidth: 'calc(100vw)',
}));

const Button = styled.div((props) => ({
  alignItems: 'center',
  display: 'flex',
  color: props.theme.colors.darkGray,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  height: '32px',
  justifyContent: 'center',
  marginRight: 'auto',
}));

const DownImage = styled.img({
  width: '15px',
  height: '15px',
  margin: '0 5px',
  objectFit: 'contain',
  objectPosition: 'center',
});

const NFTStatus = styled.div<MobileProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: ${props.mobile ? '0' : '7px'};
    display: flex;
    align-items: center;
    color: ${props.mobile ? props.theme.colors.darkGray : props.theme.colors.gray6
    };
  `,
);

type MobileFilterProps = {
  expanded: boolean;
};

const MobileFilter = styled.div<MobileFilterProps>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 800,
  top: '80px',
  left: 0,
  width: '100vw',
  height: '100vh',
  transition: 'transform 0.3s ease-in-out',
  backgroundColor: '#0000',
  transform: props.expanded ? 'translateY(0)' : 'translateY(-120%)',
}));

const MenuBody = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: props.theme.colors.gray2,
  padding: '16px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.4)',
}));

const FieldTitle = styled.div`
  font-size: 16px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.darkGray};
  font-weight: 600;
`;

interface InputContainerProps {
  half?: boolean;
  dropDown?: boolean;
}

const InputContainer = styled.div<InputContainerProps>(
  (props) => `
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    flex: ${props.half ? '1' : 'unset'};
    margin-bottom: ${props.half ? '0' : '16px'};
    height: 40px;
    padding: ${props.dropDown ? '8px 14px 8px 16px' : '0'};
    position: relative;
  `,
);

const SearchIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
`;

const SearchField = styled.input`
  display: flex;
  flex: 1;
  height: 40;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkGray};
  border: none;
  outline: none;
`;

const BlurDiv = styled.div`
  display: flex;
  flex: 1;
  background: rgba(196, 196, 196, 0.01);
  backdrop-filter: blur(10px);
`;

const FilterContainer = styled.div`
  z-index: 900;
  display: flex;
  flex: 1;
  background-color: ${(props) => props.theme.colors.gray2};
`;

interface RowContainerProps {
  first?: boolean;
}

const RowContainer = styled.div<RowContainerProps>(
  (props) => `
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: ${props.first ? '32px' : '0'};
  `,
);

const RowItem = styled.div<RowContainerProps>(
  (props) => `
    display: flex;
    flex: 1;
    margin: ${props.first ? '0 10px 0 0' : '0 0 0 10px'};
  `,
);

interface CancelProps {
  cancel?: boolean;
}

const CancelButton = styled.div<CancelProps>(
  (props) => `
    border: 1px solid #01998C;
    flex: 1;
    height: 40px;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    font-size: ${props.cancel ? '16px' : '20px'};
    line-height: 40px;
    background-color: ${props.cancel ? '#0000' : '#01998C'};
    color: ${props.cancel ? props.theme.colors.darkGray : '#FFFFFF'};
    font-weight: ${props.cancel ? '500' : '700'};
  `,
);

export const ResultsHeader = () => {
  const { isMobile } = useWindowSize();
  const { visible, toggleVisible } = useContext(FilterContext);
  const [sortVisible, toggleSortVisible] = useState(false);
  const [creatorVisible, toggleCreatorVisible] = useState(false);
  const [minVisible, toggleMinVisible] = useState(false);
  const [maxVisible, toggleMaxVisible] = useState(false);

  const creatorOptions = ['Creator1', 'Creator2', 'Creator3', 'Creator4'];
  const creatorData = creatorOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const minPriceOptions = ['1', '2', '3', '4'];
  const minPriceData = minPriceOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const maxPriceOptions = ['100', '200', '300', '400'];
  const maxPriceData = maxPriceOptions.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <SortHeader mobile={isMobile}>
      <MobileFilter expanded={visible}>
        <MenuBody>
          <FieldTitle>Search</FieldTitle>
          <InputContainer>
            <SearchIcon src={SearchImg} />
            <SearchField placeholder="Type to search..." />
          </InputContainer>
          <DropdownFilter
            title="Sort"
            placeholder="Most Popular"
            toggleVisible={(val) => toggleSortVisible(val)}
            visible={sortVisible}
          />
          <DropdownFilter
            title="Filter"
            placeholder="All Creators"
            toggleVisible={(val) => toggleCreatorVisible(val)}
            visible={creatorVisible}
          />
          <RowContainer first>
            <DropdownFilter
              style={{ marginRight: true }}
              dropUp
              half
              placeholder="No Min Price"
              toggleVisible={(val) => toggleMinVisible(val)}
              visible={minVisible}
            />
            <DropdownFilter
              style={{ marginLeft: true }}
              dropUp
              half
              placeholder="No Max Price"
              toggleVisible={(val) => toggleMaxVisible(val)}
              visible={maxVisible}
            />
          </RowContainer>
          <RowContainer>
            <RowItem first>
              <CancelButton cancel>CLEAR</CancelButton>
            </RowItem>
            <RowItem>
              <CancelButton>DONE</CancelButton>
            </RowItem>
          </RowContainer>
        </MenuBody>
        <BlurDiv></BlurDiv>
      </MobileFilter>
      {isMobile ? (
        <FilterContainer>
          <Button onClick={() => toggleVisible(!visible)}>
            SORT & FILTER
            <DownImage src={visible ? UpArrowImg : DownArrowImg}></DownImage>
          </Button>
          <NFTStatus mobile={isMobile}>{'133 of 1567 NFTs'}</NFTStatus>
        </FilterContainer>
      ) : (
        <>
          <SelectPicker
            data={creatorData}
            style={{
              height: 48,
              width: isMobile ? '100%' : 200,
              marginTop: 5,
              marginRight: '16px',
            }}
            placeholder="All Creators"
          />
          <SelectPicker
            data={minPriceData}
            style={{
              height: 48,
              width: isMobile ? '100%' : 200,
              marginTop: 5,
              marginRight: '16px',
            }}
            placeholder="No Min Price"
          />
          <SelectPicker
            data={maxPriceData}
            style={{
              height: 48,
              width: isMobile ? '100%' : 200,
              marginTop: 5,
              marginRight: '32px',
            }}
            placeholder="No Max Price"
          />
          <NFTStatus>{'133 of 1567 NFTs'}</NFTStatus>
          <SortBy />
        </>
      )}
    </SortHeader>
  );
};

ResultsHeader.propTypes = {
  showFilter: PropTypes.bool,
  onShowFilter: PropTypes.func,
};
