import { TransactionReceipt } from '@ethersproject/providers';
import { store } from 'src';
import { FarmDeposit } from 'src/contracts';
import { getErc20Balances } from 'src/redux/Account/Actions';
import { sendTransaction } from './sendTransaction';

// Assumption here is that all amount checks have already taken place
export async function farmTx(amount: string) {
  const { Contracts } = store.getState();
  const { farm } = Contracts.contracts;
  if (!farm) {
    throw 'farmTx store failure: no farm contract';
  }

  // the callback on a successful receipt
  function callback(receipt: TransactionReceipt) {
    try {
      // console.log('get receipt', receipt);
      const topic = farm.interface.getEventTopic('Deposit');
      const log = receipt.logs.find((l) => l.topics.indexOf(topic) >= 0);
      const deposit = farm.interface.parseLog(log).args as FarmDeposit;
      // console.log('got deposit event', deposit);

      // saga reloads:
      // (a) my balances
      // (b) maybe prices, for APR recalc
    } catch (err) {
      console.error('failed to pase receipt', receipt, err);
    } finally {
      store.dispatch(getErc20Balances());
    }
  }

  function onError(error: any, receipt?: TransactionReceipt) {
    console.log('tx error', error);
    console.log('tx error receipt', receipt);
    // handle the error state and inform the user
    //store.dispatch(failedTransaction(tx, error));
    store.dispatch(getErc20Balances());
  }

  console.log('starting contract call');
  // The contract call via metamask
  try {
    const tx = await farm.deposit(amount);
    console.log('tx sending', tx);

    sendTransaction(tx, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
