import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import {
  buttonHeight,
  GenesisColor,
  standardObjectWidth,
} from '../cmsdriven/constants';
import { ButtonType } from 'src/typings';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps &
  ColorProps & { variant: ButtonType; isDisabled?: boolean };

export const ButtonText = styled.p<SpaceProps & ColorProps>(
  (props) => {
    return {
      fontWeight: 500,
      margin: 'auto',
      fontSize: theme.fontSizes[2],
    };
  },
  space,
  color,
);

export const ButtonStyle = styled.button<WrapperProps>(
  (props) => {
    let textColor = GenesisColor.white;
    let bgColor = GenesisColor.greyDark;
    let border = '';
    let fontWeight = 500;
    // border, fill, text colour

    if (props.isDisabled) {
      textColor = GenesisColor.greyDark;
      bgColor = GenesisColor.greyMid;
    } else {
      switch (props.variant) {
        case ButtonType.primary:
          bgColor = GenesisColor.blue;
          fontWeight = 600;
          break;
        case ButtonType.secondary:
          textColor = GenesisColor.blue;
          bgColor = GenesisColor.white;
          // need a border on this one (text color)
          border = 'fixme';
          break;
        case ButtonType.purchase:
          bgColor = GenesisColor.red;
          fontWeight = 600;
          break;
        case ButtonType.purchaseCurrencySelect:
          bgColor = GenesisColor.red;
          fontWeight = 600;
          // add info about the currency selector (or move that)
          break;
        case ButtonType.information:
          fontWeight = 600;
        default:
          break;
      }
    }
    return {
      width: standardObjectWidth,
      height: buttonHeight,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      color: textColor,
      backgroundColor: bgColor,
      border: border,
      textTransform: 'uppercase',
      fontSize: '20px',
      fontWeight: fontWeight,
    };
  },
  space,
  color,
);
