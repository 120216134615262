import React from 'react';
import { Modal } from 'rsuite';

export interface ModalProps {
  visible: boolean;
  show: (isShowing: boolean) => void;
}

export function ModalBase(props: ModalProps & { children: JSX.Element }) {
  const { visible, show, children } = props;

  const onHide = () => {
    show(false);
  };

  return (
    <Modal
      show={visible}
      backdrop
      keyboard
      autoFocus
      enforceFocus
      onHide={onHide}
    >
      {children}
    </Modal>
  );
}
