import React, { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppRouter } from './router';
import { initializeFirebase } from './util/firebase';

import { FilterContext, MetamaskContext } from './contexts';
import { useMetamask } from './hooks/useMetamask';
import { createStoryData } from 'src/redux/Stories/Actions';
import { getCurrentPrices } from './redux/ERC20/Actions';
import { getTotalStakes } from './redux/ERC20/Actions';
import { connectCachedWallet } from './util/connectWallet';
import GlobalStyle from './style';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

export const App: React.FC = () => {
  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false);
  const { walletAddr, connectWallet, updateWalletAddr } = useMetamask();
  const dispatch = useDispatch();

  const initializeModals = () => {
    initializeFirebase();
  };

  const updateMobileFilterVisibility = (val) => {
    setShowMobileFilter(val);
  };

  // get global content:
  // storyblock
  // prices
  // total stakes
  // LATER: World total supply data
  // NOTE: account specific stuff all flows through from SET_ACCOUNT
  useEffect(() => {
    dispatch(getCurrentPrices());
    dispatch(getTotalStakes());
    dispatch(createStoryData());
  }, []);

  useEffect(() => {
    if (showMobileFilter) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }
  }, [showMobileFilter]);

  useEffect(() => {
    initializeModals();
    connectCachedWallet(dispatch);
  }, []);

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <FilterContext.Provider
            value={{
              visible: showMobileFilter,
              toggleVisible: updateMobileFilterVisibility,
            }}
          >
            <MetamaskContext.Provider
              value={{
                walletAddr,
                updateWalletAddr,
                connectWallet,
              }}
            >
              <AppRouter />
            </MetamaskContext.Provider>
          </FilterContext.Provider>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  );
};
