import axios from 'axios';
import { moralisOptions } from './moralisOptions';

export const getTokenPrice = async (token: string): Promise<number> => {
  try {
    const axiosErc20Return = await axios.get(
      `https://deep-index.moralis.io/api/v2/erc20/${token}/price?chain=polygon`,
      moralisOptions,
    );

    if (axiosErc20Return.status === 200 && !!axiosErc20Return.data) {
      return axiosErc20Return.data.usdPrice;
    } else {
      console.log(`failed to get pricing for ${token}`);
      return 0;
    }
  } catch (err) {
    console.error('failed to getTokenPrice', err);
    return 0;
  }
};
