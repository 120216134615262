import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { useWindowSize } from 'src/hooks/useWindowSize';

import LoadingImg from 'src/img/loading.svg';
import { BackButton } from 'src/components/buttons/backButton';
import { ContentContainer, MobileProps } from 'src/components';

const ItemDescription = styled.div<MobileProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: ${props.mobile ? '0' : '32px'};
  `,
);

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

const ItemText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.green};
  display: flex;
  text-align: center;
`;

const LoadingImage = styled.img`
  width: 44px;
  height: 44px;
  margin: 0 0 4px;
`;

const ItemDescriptionText = styled.div(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props.theme.colors.gray6};
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 16px;
  `,
);

const Processing = () => {
  const { isMobile } = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/market/123/success');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <ContentContainer mobile={isMobile}>
      {!isMobile && <BackButton backUrl="/nft/123" />}
      <ItemDescription mobile={isMobile}>{`Creator's name`}</ItemDescription>
      <ItemTitle>
        Name of this NFTName of this NFTName of this NFTName of this NFT
      </ItemTitle>
      <CardContainer>
        <LoadingImage src={LoadingImg} />
        <ItemText>
          Transaction
          <br />
          Pending
        </ItemText>
      </CardContainer>

      <ItemDescriptionText>
        {`You’re buying [Name of this NFT] with a max price of [price] GAME. Please wait and your transaction will complete soon`}
      </ItemDescriptionText>
    </ContentContainer>
  );
};

export default Processing;
