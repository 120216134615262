import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { Main, Results } from 'src/components';
import { PageFrame } from 'src/components/page';
import { ResultsHeader } from './components/ResultsHeader';
import { ResultsBody } from './components/ResultsBody';
import { CreatorHeader } from './components/CreatorHeader';

// import { useWindowSize } from 'src/hooks/useWindowSize';
import { Colors } from 'src/constants/colors';

const Creators = () => {
  // const { isMobile } = useWindowSize();
  const [, setShowFilter] = useState(true);

  return (
    <PageFrame>
      <Main style={{ backgroundColor: Colors.gray2 }}>
        <Results full>
          <ResultsHeader onShowFilter={() => setShowFilter(true)} />
          <CreatorHeader></CreatorHeader>
          <ResultsBody />
        </Results>
      </Main>
    </PageFrame>
  );
};

export default Creators;
