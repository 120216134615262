import React from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { SiteHeaderStoryblok } from 'src/typings';
import { ArtCutout, CutoutLocation } from '../ArtCutout';
import { CtaButton } from 'src/components/buttons';
import { Title, SiteCtaButtonStyle, SiteTitle } from '../style';
import { SiteHeaderStyle } from './style';

interface SiteHeaderProps {
  data: SiteHeaderStoryblok;
}

export function SiteHeader(props: SiteHeaderProps) {
  const { isMobile } = useWindowSize();
  const { data } = props;
  console.log(data.image.filename);
  const cutout = ArtCutout(CutoutLocation.bottom, isMobile);
  const cutout1 = ArtCutout(CutoutLocation.top, isMobile);
  return (
    <div>
      <SiteHeaderStyle key={data._uid} imageUrl={data?.image?.filename}>
        {data.title && <SiteTitle header>{data.title}</SiteTitle>}
        {data.suheader && <Title header>{data.suheader}</Title>}
        {cutout}
        {isMobile ? cutout1 : <></>}
        {/*data.image && <img src={data.image.filename} alt={data.image.alt} />*/}
      </SiteHeaderStyle>
      <SiteCtaButtonStyle header>
        {data.cta &&
          data.cta.map((button) => (
            <CtaButton data={button} key={button._uid} />
          ))}
      </SiteCtaButtonStyle>
    </div>
  );
}
