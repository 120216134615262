import { JsonRpcSigner } from '@ethersproject/providers';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectingModal } from 'src/components/modals/ConnectingModal';
import { WrongNetworkModal } from 'src/components/modals/WrongNetworkModal';
import useModal from 'src/hooks/useModal';
import { AppState } from 'src/redux/CreateStore';
import { ButtonType } from 'src/typings';
import { connectWallet } from 'src/util/connectWallet';
import { switchToPolygon } from 'src/util/switchToPolygon';
import { ButtonText, ButtonStyle } from '../style';

interface CtaButtonProps {
  type: ButtonType;
  children: string;
  isDisabled?: boolean;
  onClick: (signer: JsonRpcSigner) => void;
}

export function ActionButton(props: CtaButtonProps) {
  const { type, isDisabled, children, onClick } = props;
  const wrongNetwork = useModal();
  const connecting = useModal();
  const dispatch = useDispatch();
  const signer = useSelector((state: AppState) => state.Contracts.signer);

  const clicked = async (event: React.SyntheticEvent) => {
    let ethers;
    try {
      // Pattern here is (a) if there's already a signer, check the chainId is right
      // (b) if there's no signer, go get one by connecting
      // (c) if the chainId is wrong, switch to Polygon
      // (d) pass the signer to the onClick function, for use in making calls
      // We do NOT reload on chain change; we're not tracking cross-chain information
      if (!!signer || (ethers = await connectWallet(dispatch))) {
        //show(false);
        const currentSigner: JsonRpcSigner = signer || ethers.signer;
        const chainId = await currentSigner.getChainId();
        if (chainId === 137 || (await switchToPolygon(currentSigner))) {
          onClick(currentSigner);
        } else {
          wrongNetwork.show(true);
        }
      } else {
        connecting.show(true);
      }
    } catch (err) {
      console.log('failed to connect here', err);
    }
  };

  const modal = ConnectingModal(connecting);
  const wrongNetworkModal = WrongNetworkModal(wrongNetwork);

  return (
    <>
      <ButtonStyle
        onClick={clicked}
        className={`actionButton`}
        variant={type}
        key={children}
        isDisabled={isDisabled}
      >
        <ButtonText>{children}</ButtonText>
      </ButtonStyle>
      {modal}
      {wrongNetworkModal}
    </>
  );
}
