import React from 'react';
import styled from 'styled-components';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { Main, MobileProps, Results } from 'src/components';
import { PageFrame } from 'src/components/page';

import { useWindowSize } from 'src/hooks/useWindowSize';

import MyntyLogo from 'src/img/mynty_logo.svg';
import { BackButton } from 'src/components/buttons/backButton';
import { Colors } from 'src/constants/colors';

const InnerContainer = styled.div<MobileProps>(
  (props) => `
    flex-direction: column;
    align-items: center;
    display: flex;
    flex: ${props.mobile ? '1' : 'unset'};
    padding: ${props.mobile ? '0 16px' : '0'};
  `,
);

const InnerTitle = styled.div<MobileProps>(
  (props) => `
    font-size: ${props.mobile ? '20px' : '32px'};
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    color: ${props.theme.colors.darkGray};
    margin-bottom: 20px;
  `,
);

const InnerSubTitle = styled.div<MobileProps>(
  (props) => `
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: left;
    color: ${props.theme.colors.gray6};
    margin-bottom: 16px;
    width: ${props.mobile ? '100%' : '340px'};
    word-wrap: none;
  `,
);

const CancelButton = styled.div<MobileProps>(
  (props) => `
    border: 1px solid #01998C;
    width: ${props.mobile ? '100%' : '340px'};
    height: 60px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  `,
);

const SignUpButton = styled(CancelButton)`
  background-color: ${(props) => props.theme.colors.green};
`;

const ButtonTitle = styled.div`
  color: white;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
`;

const ButtonDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-weight: 500;
`;
const ButtonTitleBlack = styled(ButtonTitle)`
  color: ${(props) => props.theme.colors.darkGray};
`;

const ButtonDescriptionBlack = styled(ButtonDescription)`
  color: ${(props) => props.theme.colors.gray6};
`;

const LogoImg = styled.img`
  height: 96px;
  margin: 20px;
`;

const InputField = styled.input<MobileProps>(
  (props) => `
    height: 40px;
    width: ${props.mobile ? '100%' : '340px'};
    background: #FFF;
    border: 1px solid #CACACA;
    outline: none;
    padding: 8px 16px;
    color: ${props.theme.colors.gray6};
    font-size: 16px;
    margin-bottom: 20px;
  `,
);

const SignUp = () => {
  const { isMobile } = useWindowSize();

  return (
    <PageFrame>
      <Main style={{ backgroundColor: Colors.gray2 }}>
        <Results mobile={isMobile}>
          <BackButton
            backUrl="/market"
            height="50px"
            padding="0 16px"
            border="none"
          />
          <InnerContainer mobile={isMobile}>
            {!isMobile && <LogoImg src={MyntyLogo}></LogoImg>}
            <InnerTitle mobile={isMobile}>CREATE YOUR ACCOUNT</InnerTitle>
            <InputField mobile={isMobile} placeholder="Username"></InputField>
            <InputField
              mobile={isMobile}
              placeholder="Email address"
            ></InputField>
            <InnerSubTitle mobile={isMobile}>
              We’ll send you an email to verify your account. We can also keep
              you informed with inside information on the latest Mynty releases.
            </InnerSubTitle>
            <SignUpButton mobile={isMobile}>
              <ButtonTitle>SIGN UP</ButtonTitle>
              <ButtonDescription>And keep me informed</ButtonDescription>
            </SignUpButton>
            <CancelButton mobile={isMobile}>
              <ButtonTitleBlack>SIGN UP</ButtonTitleBlack>
              <ButtonDescriptionBlack>
                {`I don't want the latest info`}
              </ButtonDescriptionBlack>
            </CancelButton>
          </InnerContainer>
        </Results>
      </Main>
    </PageFrame>
  );
};

export default SignUp;
