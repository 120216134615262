import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Constants
import { SocialLinks } from 'src/constants/social';

// components
import {
  Wrapper,
  Social,
  SocialSection,
  SocialSectionRight,
  SocialSectionInner,
  Links,
  Link,
  LinkContent,
  ContactSection,
  StayUpToDate,
  EmailSignUp,
} from './style';

export const Footer = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Wrapper>
      <ContactSection>
        <StayUpToDate>
          <h1>stay up to date</h1>
          <p>
            Be the first to hear about new Genesis events, world sales, and more
          </p>
        </StayUpToDate>

        <EmailSignUp>
          <input type="email" placeholder="Enter email address" />
          <button>Sign me up</button>
        </EmailSignUp>
      </ContactSection>
      <Social>
        <SocialSection>
          <Link href="/genesis-token">
            <h1>GENESIS TOKEN</h1>
          </Link>
          <Link href="/worlds">
            <h1>WORLDS</h1>
          </Link>
          <Link href="/rewards">
            <h1>REWARDS</h1>
          </Link>
          <Link href="/game">
            <h1>GAME</h1>
          </Link>
          <Link href="/community">
            <h1>COMMUNITY</h1>
          </Link>
        </SocialSection>
        <SocialSectionRight>
          <SocialSectionInner>
            {Object.keys(SocialLinks).map((key, index) => {
              return (
                <Link
                  key={`footer-item-${index}`}
                  href={SocialLinks[key]}
                  target="_blank"
                  rel="noopenr noreferrer"
                >
                  <h1>{key}</h1>
                </Link>
              );
            })}
          </SocialSectionInner>
        </SocialSectionRight>
      </Social>

      <Links>
        <p>(c) 2021 NFT Platform Ltd</p>
        <LinkContent>
          <Link
            href="https://hub.genesis.game/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms
          </Link>{' '}
          <span>|</span>
          <Link
            href="https://hub.genesis.game/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy
          </Link>{' '}
          <span>|</span>
          <Link
            href="https://hub.genesis.game/content"
            target="_blank"
            rel="noopener noreferrer"
          >
            content
          </Link>{' '}
          <span>|</span>
          <Link
            href="https://hub.genesis.game/ethics"
            target="_blank"
            rel="noopener noreferrer"
          >
            ethics
          </Link>
        </LinkContent>
      </Links>
    </Wrapper>
  );
};
