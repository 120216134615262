import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import { useHistory } from 'react-router';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { useWindowSize } from 'src/hooks/useWindowSize';

import { BackButton } from 'src/components/buttons/backButton';
import { ContentContainer, MobileProps } from 'src/components';
import { Colors } from 'src/constants/colors';

const ItemDescription = styled.div<MobileProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: ${props.mobile ? '0' : '32px'};
  `,
);

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ItemPrice = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 16px 0 8px 0;
  text-align: center;
  color: ${(props) => props.theme.colors.red};
`;

const ItemPriceDescription = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  flex-direction: row;
  margin: 16px 16px 0;
`;

const ItemPriceContainer = styled.div`
  margin: 0 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ItemPriceText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.gray5};
  display: flex;
  flex-direction: row;
`;

const BuyBtn = styled.div(
  (props) => `
    height: 40px;
    background-color: ${props.theme.colors.red};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
  `,
);

const DisabledBtn = styled(BuyBtn)`
  background-color: ${(props) => props.theme.colors.gray5};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkGray};
`;

const ItemDescriptionText = styled.div<{ top?: boolean; bottom?: boolean }>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props.theme.colors.gray6};
    display: flex;
    flex-direction: row;
    margin-top: ${props.top ? '16px' : 0};
    margin-bottom: ${props.bottom ? '16px' : 0};
  `,
);

const UnderlineText = styled(ItemDescriptionText)`
  color: ${(props) => props.theme.colors.black};
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
`;

const OwnerText = styled(ItemDescriptionText)`
  margin: 16px 0 8px;
  color: ${(props) => props.theme.colors.darkGray};
`;

const NormalText = `The price of bonded NFTs changes as people buy and sell them. If the market price goes above 6,250 GAME, your purchase will be cancelled and you won’t spend any GAME. If the market price when your transaction completes is lower than 6,250 GAME, you’ll only pay the market price.`;

const BuyText = (value) =>
  `You need more GAME. You have ${value} GAME on Polygon network.`;

const AddFund = ({ min = 0, max = 100 }: { min: number; max: number }) => {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const [amount, setAmount] = useState(50);

  return (
    <ContentContainer mobile={isMobile}>
      {!isMobile && <BackButton backUrl="/nft/123" />}
      <ItemDescription mobile={isMobile}>{`Creator's name`}</ItemDescription>
      <ItemTitle>
        Name of this NFTName of this NFTName of this NFTName of this NFT
      </ItemTitle>
      <CardContainer>
        <ItemPriceDescription>
          {`We'll find the cheapest price for you.\nSet your buy limit:`}
        </ItemPriceDescription>
        <ItemPrice>{amount} GAME</ItemPrice>

        <Slider
          style={{ margin: '0 16px', width: 'calc(100% - 32px)' }}
          trackStyle={{ height: '10px', backgroundColor: Colors.red }}
          railStyle={{ height: '10px', backgroundColor: Colors.gray5 }}
          handleStyle={{
            width: '32px',
            height: '32px',
            backgroundColor: Colors.red,
            border: 'none',
            marginTop: '-11px',
          }}
          min={min}
          max={max}
          startPoint={0}
          value={amount}
          onChange={setAmount}
        />
        <ItemPriceContainer>
          <ItemPriceText>{min}</ItemPriceText>
          <ItemPriceText>{max}</ItemPriceText>
        </ItemPriceContainer>
      </CardContainer>
      {amount > 60 ? (
        <DisabledBtn>{'INSUFFICIENT FUNDS'}</DisabledBtn>
      ) : (
        <BuyBtn
          onClick={() => history.push('/market/123/processing')}
        >{`BUY: ${amount} GAME`}</BuyBtn>
      )}

      <ItemDescriptionText top bottom={amount > 60}>
        {amount > 60 ? BuyText(200) : NormalText}
      </ItemDescriptionText>
      {amount > 60 && (
        <BuyBtn
          onClick={() => history.push('/market/123/processing')}
        >{`GET GAME`}</BuyBtn>
      )}
      <OwnerText>TRENDS</OwnerText>
      <ItemDescriptionText>
        {'128 bought | 71 sold | '}
        <UnderlineText>Bonding curve</UnderlineText>
      </ItemDescriptionText>
    </ContentContainer>
  );
};

export default AddFund;
