import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { useWindowSize } from 'src/hooks/useWindowSize';

import { BackButton } from 'src/components/buttons/backButton';
import { ContentContainer, MobileProps } from 'src/components';
import CheckImg from 'src/img/check_icon.svg';
import CrossImg from 'src/img/cross_icon.svg';

const ItemDescription = styled.div<MobileProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: ${props.mobile ? '0' : '32px'};
  `,
);

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardContainer = styled.div<{ succeed: boolean }>(
  (props) => `
    background-color: #FFF;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    color: ${props.succeed ? props.theme.colors.green : props.theme.colors.red};
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
  `,
);

const BuyBtn = styled.div<{ border?: boolean }>(
  (props) => `
    height: 40px;
    background-color: ${
      props.border ? 'transparent' : props.theme.colors.green
    };
    border: ${props.border ? `1px solid ${props.theme.colors.green}` : 'none'};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: ${props.border ? '16px' : '20px'};
    line-height: 24px;
    text-align: center;
    color: ${
      props.border ? props.theme.colors.gray6 : props.theme.colors.white
    };
    font-family: Montserrat;
    font-style: normal;
    font-weight: ${props.border ? '500' : 'bold'};
    margin-bottom: 16px;
  `,
);

const ItemDescriptionText = styled.div(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props.theme.colors.gray6};
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 16px;
  `,
);

const IconImage = styled.img`
  height: 55px;
  margin: 16px 0;
`;

const Completion = ({ succeed = false }: { succeed: boolean }) => {
  const { isMobile } = useWindowSize();
  const history = useHistory();

  return (
    <ContentContainer mobile={isMobile}>
      {!isMobile && <BackButton backUrl="/nft/123" />}
      <ItemDescription mobile={isMobile}>{`Creator's name`}</ItemDescription>
      <ItemTitle>
        Name of this NFTName of this NFTName of this NFTName of this NFT
      </ItemTitle>
      <CardContainer succeed={succeed}>
        <IconImage src={succeed ? CheckImg : CrossImg} />
        {succeed ? 'SUCCESS!' : 'FAILED'}
      </CardContainer>
      <ItemDescriptionText>
        {`You bought [Name of this NFT] for 500 GAME.`}
      </ItemDescriptionText>
      <BuyBtn onClick={() => history.push('/market')}>
        {'CONTINUE SHOPPING'}
      </BuyBtn>
      <BuyBtn border onClick={() => history.push('/collection')}>
        {'VIEW COLLECTION'}
      </BuyBtn>
    </ContentContainer>
  );
};

export default Completion;
