/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useHistory } from 'react-router';

type useMetamaskProp = {
  walletAddr: string;
  updateWalletAddr: (addr: string) => void;
  connectWallet: () => {};
};

// Hook
export const useMetamask = (): useMetamaskProp => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [walletAddr, setWalletAddr] = useState('');

  const connectWallet = async () => {
    if (walletAddr.length > 0) {
      return;
    }
    // Connect to the network
    await window.ethereum.enable();
    // const provider = ethers.getDefaultProvider('kovan')
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const currentNetwork = await provider.getNetwork();
    if (currentNetwork.chainId === 42) {
      const signer = provider.getSigner();
      const walletAddress = await signer.getAddress();
      setWalletAddr(walletAddress);
    } else {
      setWalletAddr('');
    }
  };

  const updateWalletAddr = (addr: string) => {
    setWalletAddr(addr);
  };

  useEffect(() => {
    // Handler to call on window resize
    // connectWallet();
  }, []); // Empty array ensures that effect is only run on mount

  return {
    walletAddr,
    updateWalletAddr,
    connectWallet,
  };
};
