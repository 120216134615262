import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { useWindowSize } from 'src/hooks/useWindowSize';

import { BackButton } from 'src/components/buttons/backButton';
import { ContentContainer, MobileProps } from 'src/components';

const ItemDescription = styled.div<MobileProps>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: ${props.mobile ? '0' : '32px'};
  `,
);

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const ItemPrice = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: ${(props) => props.theme.colors.green};
  margin: 12px 0 0 16px;
`;

const ItemPriceUnit = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin-top: 22px;
`;

const ItemPriceContainer = styled.div`
  margin: 2px 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ItemPriceText = styled.div<{
  brighter?: boolean;
  inner?: boolean;
}>(
  (props) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: ${props.inner ? '400' : '500'};
    font-size: 16px;
    line-height: 24px;
    color: ${
      props.brighter ? props.theme.colors.gray6 : props.theme.colors.darkGray
    };
    display: flex;
    flex-direction: row;
  `,
);

const BuyBtn = styled.div`
  height: 40px;
  background-color: ${(props) => props.theme.colors.green};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
`;

const OwnerText = styled(ItemPriceText)`
  margin: 16px 0 8px;
`;

const UnderlineText = styled(ItemPriceText)`
  color: ${(props) => props.theme.colors.black};
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
`;

const Redeem = () => {
  const { isMobile } = useWindowSize();
  const history = useHistory();

  return (
    <ContentContainer mobile={isMobile}>
      {!isMobile && <BackButton backUrl="/market"></BackButton>}
      <ItemDescription mobile={isMobile}>{`Creator's name`}</ItemDescription>
      <ItemTitle>
        Name of this NFTName of this NFTName of this NFTName of this NFT
      </ItemTitle>
      <CardContainer>
        <ItemPrice>
          990
          <ItemPriceUnit>GAME</ItemPriceUnit>
        </ItemPrice>
        <ItemPriceContainer>
          <ItemPriceText inner>{'0 copies'}</ItemPriceText>
        </ItemPriceContainer>
        <BuyBtn onClick={() => history.push('/market/123/fund')}>
          {'CONFIRM BURN'}
        </BuyBtn>
      </CardContainer>
      <OwnerText>SLIPPAGE</OwnerText>
      <ItemPriceText brighter>
        {`If other users buy or sell this token between now and when you make your transaction, the amount of GAME you receive may be higher or lower than the current award of 5,400 GAME.`}
      </ItemPriceText>
      <OwnerText>TRENDS</OwnerText>
      <ItemPriceText>
        {'128 bought | 71 sold | '}
        <UnderlineText>Bonding curve</UnderlineText>
      </ItemPriceText>
    </ContentContainer>
  );
};

export default Redeem;
