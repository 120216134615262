import React from 'react';
import PropTypes from 'prop-types';

import { LoadingIndicator } from '../loading';
import { Wrapper, PageContainer } from './style';

export const PageFrame = ({ loading, children }) => {
  return loading ? (
    <LoadingIndicator />
  ) : (
    <Wrapper>
      <PageContainer>{children}</PageContainer>
    </Wrapper>
  );
};

PageFrame.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};
