import axios from 'axios';
import { contract } from 'src/contracts';
import { moralisOptions } from './moralisOptions';

export interface Stakes {
  farm: string;
  governance: string;
}

export const getTotalStaked = async (): Promise<Stakes> => {
  const staked: Stakes = { farm: '0', governance: '0' };
  try {
    const axiosFarmReturn = await axios.get(
      `https://deep-index.moralis.io/api/v2/${contract.side.farm}/erc20?chain=polygon`,
      moralisOptions,
    );

    if (axiosFarmReturn.status === 200 && !!axiosFarmReturn.data) {
      for (let i = 0; i < axiosFarmReturn.data.length; i++) {
        const balanceResult = axiosFarmReturn.data[i];
        if (balanceResult.token_address === contract.side.game) {
          staked.farm = balanceResult.balance;
          break;
        }
      }
    } else {
      console.log(`failed to get GAME staked in the FARM`);
    }

    const axiosGovernanceReturn = await axios.get(
      `https://deep-index.moralis.io/api/v2/${contract.side.governance}/erc20?chain=polygon`,
      moralisOptions,
    );

    if (axiosGovernanceReturn.status === 200 && !!axiosGovernanceReturn.data) {
      for (let i = 0; i < axiosGovernanceReturn.data.length; i++) {
        const balanceResult = axiosGovernanceReturn.data[i];
        if (balanceResult.token_address === contract.side.genesis) {
          staked.governance = balanceResult.balance;
          break;
        }
      }
    } else {
      console.log(`failed to get GAME staked in the FARM`);
    }
  } catch (err) {
    console.error('failed to getTotalStaked', err);
  }
  return staked;
};
