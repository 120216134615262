import React from 'react';
import { Story } from './Story';
import { useSelector } from 'react-redux';
import { StoryStoryblok } from 'src/typings';
import { PageFrame } from '../page';
import { Main } from '..';
import { useLocation } from 'react-router-dom';

export * from './constants';
export * from './ArtCutout';
export * from './PageSection';
export * from './SiteHeader';
export * from './Story';
export * from './WorldGrid';
export * from './media';

interface ParamTypes {
  slug: string;
}

function StoryPage(props) {
  // get the url slug
  // compare it to the storyblok db
  // if it's there, render it
  const location = useLocation();
  let slug = location.pathname.slice(1);
  console.log('slug:', slug);
  if (slug === '/' || slug === '') {
    slug = 'home';
  }

  const stories: StoryStoryblok[] = useSelector(
    (state: any) => state.Stories.stories,
  );
  let story = stories[slug];
  if (!story) {
    console.log('story not found for slug', slug);
    slug = '404';
    story = stories[slug];
  }

  // handle 404 page here if not found

  return (
    <PageFrame loading={!story}>
      <Main>{!!story && Story(story)}</Main>
    </PageFrame>
  );
}

export default StoryPage;
