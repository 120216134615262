import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';

type WrapperProps = SpaceProps & ColorProps;

export const Wrapper = styled.div<WrapperProps>(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 72px)',
    overflow: 'hidden',
  }),
  space,
  color,
);

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  @media (max-width: 750px) {
    min-width: 100vw;
    width: 100vw;

    &__blur {
      filter: blur(5px);
    }
  }
`;
