import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import LeftArrowWhiteImg from 'src/img/left_arrow_white.svg';
import LeftArrowImg from 'src/img/left_arrow.svg';

const ButtonContainer = styled.div`
  height: 40px;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-right: auto;
  cursor: pointer;
`;

const ButtonImage = styled.img`
  width: 9px;
  height: 18px;
  margin-right: 8px;
`;

export const BackButton = ({
  backUrl,
  white = false,
  marginBottom = '0px',
  height = '40px',
  border = '1px solid #CACACA',
  padding = '0 8px',
}) => {
  const history = useHistory();

  return (
    <ButtonContainer
      onClick={() => history.goBack()}
      style={{ marginBottom, height, border, padding }}
    >
      <ButtonImage src={white ? LeftArrowWhiteImg : LeftArrowImg} />
      BACK
    </ButtonContainer>
  );
};

BackButton.propTypes = {
  backUrl: PropTypes.string,
  white: PropTypes.bool,
  marginBottom: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
};
