export enum Routes {
  home = '/',
  genesis = '/genesis',
  worlds = '/worlds',
  rewards = '/rewards',
  community = '/community',
  game = '/game',
  explore = '/explore',
}

export const RouteTitles = {
  [Routes.home]: 'Home',
  [Routes.genesis]: 'Genesis',
  [Routes.worlds]: 'Worlds',
  [Routes.rewards]: 'Rewards',
  [Routes.community]: 'Community',
  [Routes.game]: 'Game',
  [Routes.explore]: 'Explore',
};
