import { TransactionReceipt } from '@ethersproject/providers';
import { store } from 'src';
import { contract, Erc20Approval } from 'src/contracts';
import { sendTransaction } from './sendTransaction';

const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export async function farmAllowanceTx() {
  const { Contracts } = store.getState();
  const { game } = Contracts.contracts;
  if (!game) {
    throw 'farmAllowanceTx store failure: no GAME contract';
  }

  // the callback on a successful receipt
  function callback(receipt: TransactionReceipt) {
    try {
      const topic = game.interface.getEventTopic('Approval');
      const log = receipt.logs.find((l) => l.topics.indexOf(topic) >= 0);
      const parsed = game.interface.parseLog(log).args as Erc20Approval;
      console.log('callback log & parsedlog', parsed);
    } catch (err) {
      console.error('failed to pase receipt', receipt, err);
    } finally {
      //store.dispatch(getApprovals(for game));
    }
  }

  function onError(error: any, receipt?: TransactionReceipt) {
    console.log('tx error', error);
    console.log('tx error receipt', receipt);
    // handle the error state and inform the user
    //store.dispatch(getApprovals(for game));
  }

  console.log('starting contract call');
  // The contract call via metamask
  try {
    const tx = await game.approve(contract.side.farm, MAX_UINT256);
    console.log('tx sending', tx);

    sendTransaction(tx, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
