import React from 'react';
import useModal from 'src/hooks/useModal';
import { ButtonType, StoryblokDesktopLayout } from 'src/typings';
import { FarmGameModal, HarvestGameModal } from '../../modals';
import { ActionButton } from 'src/components/buttons';
import calculateFarmApr from 'src/util/calculateFarmApr';
import { contract } from 'src/contracts';
import { formatUnits } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { DataTable } from '../../table';
import { FarmGenesisSectionStyle } from './style';
import {
  PageSectionParagraphStyle,
  PageSectionTextStyle,
} from '../PageSection/style';

export function FarmGenesisSection() {
  const { visible: farmVisible, show: farmShow } = useModal();
  const { visible: harvestVisible, show: harvestShow } = useModal();
  const signer = useSelector((state: AppState) => state.Contracts.signer);
  const contractBalances = useSelector(
    (state: AppState) => state.Account.contractBalances,
  );
  const sideBalances = useSelector(
    (state: AppState) => state.Account.sideBalances,
  );

  const prices = useSelector((state: AppState) => state.ERC20.prices);
  const farmApr = calculateFarmApr(contractBalances, prices);

  const gameBalance = sideBalances.erc20[contract.side.game]?.balance || '0';
  const farmBalance = sideBalances.erc20[contract.side.farm]?.balance || '0';

  const friendlyFarmBalance = Math.floor(
    parseFloat(formatUnits(farmBalance, 18)),
  );

  const friendlyGameBalance = Math.floor(
    parseFloat(formatUnits(gameBalance, 18)),
  );

  // genesis earned = gFARM balance
  const genesisEarned = 'calculate this';

  const farmTitle = 'GAME > GENESIS Farming';
  const farmRows = [
    {
      data: `${farmApr.gameLocked}`,
      label: 'GAME Locked',
    },
    {
      data: `${farmApr.genesisPerDay}`,
      label: 'GENESIS Per Day',
    },
    {
      data: `${farmApr.apr}%`,
      label: 'APR',
    },
  ];
  const farmButton = (
    <ActionButton type={ButtonType.primary} onClick={() => farmShow(true)}>
      Farm GENESIS
    </ActionButton>
  );
  const harvestTitle = 'Harvest GENESIS';
  const harvestRows = [
    {
      data: `${friendlyFarmBalance}`,
      label: 'My GAME staked',
    },
    {
      data: `${genesisEarned}`,
      label: 'My farmed GENESIS',
    },
  ];
  const harvestButton = (
    <ActionButton type={ButtonType.purchase} onClick={() => harvestShow(true)}>
      Harvest GENESIS
    </ActionButton>
  );

  const connectButton = (
    <ActionButton
      type={ButtonType.information}
      onClick={() => {
        console.log('Connecting to Web3');
      }}
    >
      Connect Web3
    </ActionButton>
  );

  const startDate = new Date(1633071600).toLocaleString();

  return (
    <FarmGenesisSectionStyle>
      <PageSectionTextStyle isFullWidth>
        <PageSectionParagraphStyle isFullWidth>
          <h5>Farming Is Not Yet Live</h5>
          <p>
            GENESIS farming start at {startDate}. You can lock in your GAME now
            so you start earning right at the start time.
          </p>
        </PageSectionParagraphStyle>
      </PageSectionTextStyle>

      <DataTable title={farmTitle} rows={farmRows} actionButton={farmButton} />
      {signer ? (
        <DataTable
          title={harvestTitle}
          rows={harvestRows}
          actionButton={harvestButton}
        />
      ) : (
        <DataTable title={''} rows={[]} actionButton={connectButton} />
      )}

      <FarmGameModal visible={farmVisible} show={farmShow} farmApr={farmApr} />
      <HarvestGameModal visible={harvestVisible} show={harvestShow} />
    </FarmGenesisSectionStyle>
  );
}
