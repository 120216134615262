import React from 'react';
import { Modal } from 'rsuite';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps } from '.';
import { ActionButton } from 'src/components/buttons';

export function HarvestGameModal(props: ModalProps) {
  const { visible, show } = props;
  const confirm = () => {
    console.log('fired confirm harvest');
    console.log(
      'if the farmed GAME is 0, this should do nothing; probably cannot even open this',
    );
    console.log('this should fire the appropriate transaction');
    show(false);
  };

  const children = (
    <>
      <Modal.Header>
        <h3>Harvest your GAME and GENESIS</h3>
      </Modal.Header>
      <Modal.Body>
        <p>Show amount of GAME staked</p>
        <p>Show amount of GENESIS to be collected</p>
        <p>Unstake GAME, and collect your GENESIS</p>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton type={ButtonType.primary} onClick={confirm}>
          Harvest
        </ActionButton>
      </Modal.Footer>
    </>
  );

  return (
    <ModalBase visible={visible} show={show}>
      {children}
    </ModalBase>
  );
}
