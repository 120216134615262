export const contract = {
  main: {
    genesis: '0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437', // TEST FAKE
    game: '0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437', // REAL
    usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // REAL
    usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7', // REAL
    matic: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0', // REAL
    quick: '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f', // REAL
  },
  mainNative: 'ETH',
  side: {
    game: '0x8d1566569d5b695d44a9a234540f68d393cdc40d', // REAL
    usdc: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // REAL
    usdt: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // REAL
    weth: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // REAL
    wmatic: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', // REAL
    quick: '0x831753dd7087cac61ab5644b308642cc1c33dc13', // REAL
    genesis: '0x60A1c159FfFe4eB5cD0cec6876221cD82778F07A', // TEST FAKE
    governance: '0x9462363B7594eeD14fFF1EB42E08c65D2B4bCb0b', // TEST FAKE
    farm: '0x87666C98d75cab0A6253A916B10d3ded9DCc001a', // TEST FAKE
    world: '0x59faFd32593eef093A580E8aC7b36c8D1Fad48E3', // TEST FAKE
  },
  sideNative: 'MATIC',
};

export enum ContractNames {
  genesis = 'GENESIS',
  game = 'GAME',
  matic = 'MATIC',
  weth = 'WETH',
  usdc = 'USDC',
  usdt = 'USDT',
  quick = 'QUICK',
  governance = 'GOVERNACE',
}

export * from './ContractTypes';
