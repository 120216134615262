import React, { useEffect } from 'react';
import { Modal } from 'rsuite';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps } from '.';
import { ActionButton } from 'src/components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { useState } from 'react';
import { contract } from 'src/contracts';
import { FarmApr } from 'src/util/calculateFarmApr';
import Input from '../numericalInput';
import { ethers } from 'ethers';
import { farmAllowanceTx } from 'src/transactions/farmAllowanceTx';
import { farmTx } from 'src/transactions/farmTx';
import { floatToWei } from 'src/util';

export function FarmGameModal(props: ModalProps & { farmApr: FarmApr }) {
  const { visible, show, farmApr } = props;

  const [formAmount, setFormAmount] = useState('0.0');

  const allowances = useSelector((state: AppState) => state.Account.allowances);
  const sideBalances = useSelector(
    (state: AppState) => state.Account.sideBalances,
  );

  const gameBalance = sideBalances.erc20[contract.side.game]?.balance || '0';
  const genesisBalance =
    sideBalances.erc20[contract.side.genesis]?.balance || '0';
  const farmBalance = sideBalances.erc20[contract.side.farm]?.balance || '0';
  const friendlyGameBalance = Math.floor(
    parseFloat(ethers.utils.formatUnits(gameBalance, 18)),
  );

  const gameAllowances = allowances[contract.side.game] || {};
  const gameFarmAllowance = gameAllowances[contract.side.farm] || '0';

  useEffect(() => {
    const friendlyBalance = Math.floor(
      parseFloat(ethers.utils.formatUnits(gameBalance, 18)),
    );
    console.log('setting form amount useEffect', friendlyBalance);
    setFormAmount(friendlyBalance.toString());
  }, [sideBalances]);

  // no need for allowances for farming; we will need this for world sales in other currencies
  const setAllowance = async () => {
    await farmAllowanceTx();
  };

  const farm = async () => {
    await farmTx(floatToWei(formAmount));
    show(false);
  };

  function onFormAmountInput(input: string) {
    const endPoint = input.endsWith('.') ? '.' : '';
    if (isNaN(parseFloat(input))) {
      console.log('setting form amount nan');
      setFormAmount(`0${endPoint}`);
    } else {
      let inputNum = parseFloat(input);
      inputNum = inputNum <= 0.0 ? 0.0 : inputNum;
      if (inputNum > friendlyGameBalance) {
        setFormAmount(`${friendlyGameBalance}`);
      } else {
        setFormAmount(`${inputNum}${endPoint}`);
      }
    }
  }

  const children = (
    <>
      <Modal.Header>
        <h3>Farm GENESIS with your GAME</h3>
      </Modal.Header>
      <Modal.Body>
        <p>Choose how much GAME to stake in the farming contract:</p>
        <Input value={formAmount} onUserInput={onFormAmountInput} />
        <p>
          You&apos;ll earn {farmApr.genesisPerDay} GENESIS per day, for a total
          of <b>{farmApr.apr}% APR</b>
        </p>
        <p>
          GAME Balance: {gameBalance} / {friendlyGameBalance} GENESIS balance:{' '}
          {genesisBalance} FARM balance: {farmBalance}
        </p>
        <p>Total locked: {farmApr.gameLocked}</p>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          type={ButtonType.primary}
          onClick={setAllowance}
          isDisabled={gameBalance === '0'}
        >
          Approve GAME
        </ActionButton>
        <ActionButton
          type={ButtonType.primary}
          onClick={farm}
          isDisabled={gameFarmAllowance === '0'}
        >
          Stake GAME
        </ActionButton>
      </Modal.Footer>
    </>
  );

  return (
    <ModalBase visible={visible} show={show}>
      {children}
    </ModalBase>
  );
}
