import * as ActionTypes from '../ActionTypes';

export const getCurrentPrices = () => ({
  type: ActionTypes.GET_CURRENT_PRICES,
});

export const getPricesSuccess = (data) => ({
  type: ActionTypes.GET_PRICES_SUCCESS,
  data,
});

export const getPricesError = (error) => ({
  type: ActionTypes.GET_PRICES_ERROR,
  error,
});

export const getTotalStakes = () => ({
  type: ActionTypes.GET_TOTAL_STAKES,
});

export const getTotalStakesSuccess = (data) => ({
  type: ActionTypes.GET_TOTAL_STAKES_SUCCESS,
  data,
});

export const getTotalStakesError = (error) => ({
  type: ActionTypes.GET_TOTAL_STAKES_ERROR,
  error,
});
