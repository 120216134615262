import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { Main, MainProps, MobileProps, Results } from 'src/components';
import { PageFrame } from 'src/components/page';

import { useWindowSize } from 'src/hooks/useWindowSize';

import LeftArrowWhiteImg from 'src/img/left_arrow_white.svg';
import DotImg from 'src/img/dots.svg';
import AddFund from './AddFund';
import Processing from './Processing';
import NFTDetail from './NFTDetail';
import Completion from './Completion';
import { Colors } from 'src/constants/colors';
import Redeem from './Redeem';

const NFTImage = styled.img<MobileProps & MainProps>((props) => ({
  width: !props.mobile || props.main ? '100%' : '144px',
  maxHeight: props.mobile ? 'unset' : 'calc(100% - 136px)',
  objectPosition: 'center',
  objectFit: 'contain',
}));

const NFTImageContainer = styled.div<MobileProps & MainProps>((props) => ({
  flex: !props.mobile || props.main ? 1 : 'unset',
  display: 'flex',
  height: props.mobile
    ? props.main
      ? 'calc(100vw - 32px)'
      : '144px'
    : 'calc(100vh - 136px)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: props.mobile ? '0' : '0 10%',
}));

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  background-color: ${(props) => props.theme.colors.gray7};
  font-size: 14px;
  line-height: 16px;
  color: white;
  text-align: center;
  font-weight: 600;
  padding: 0 16px;
  justify-content: center;
`;

interface LeftButtonProps {
  left?: boolean;
}

const HeaderBtn = styled.div<LeftButtonProps>(
  (props) => `
    display: flex;
    align-items: center;
    flex: 1;
    background-color: ${props.theme.colors.gray7};
    justify-content: ${props.left ? 'flex-start' : 'flex-end'};
    cursor: pointer;
    text-align: ${props.left ? 'left' : 'right'};
  `,
);

const HeaderImg = styled.img<LeftButtonProps>(
  (props) => `
    width: ${props.left ? '8px' : '4px'};
    height: 16px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
  `,
);

const NFTSingle = () => {
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const history = useHistory();
  const [nftData] = useState({
    image:
      'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
    col: 'col1',
    name: 'item1',
    price: 1,
    descrtiption:
      'A cargo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. oo ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. ship or freighter is a neutral ship that carries cargo, goods, and units from bases to battles. You’ll find specialized types of cargo vessels include port ships, containers, soso on',
  });

  const handleGoBack = () => {
    const locations = location.pathname.split('/');
    if (locations.length === 3) {
      history.push('/market');
    } else if (locations.length === 4) {
      history.push('/market/123');
    }
  };

  const renderMobileHeader = () => {
    const locations = location.pathname.split('/');
    if (locations.length === 3) {
      return (
        <MobileHeader>
          <HeaderBtn onClick={() => handleGoBack()} left>
            <HeaderImg src={LeftArrowWhiteImg} left />
            BACK
          </HeaderBtn>
          <HeaderBtn>
            ACTIONS
            <HeaderImg src={DotImg} />
          </HeaderBtn>
        </MobileHeader>
      );
    } else {
      const lastPoint = locations.pop();
      return (
        <MobileHeader>
          {lastPoint === 'fund'
            ? 'CHECKOUT'
            : lastPoint === 'processing'
            ? 'PROCESSING'
            : 'COMPLETE'}
        </MobileHeader>
      );
    }
  };

  return (
    <PageFrame>
      <Main style={{ backgroundColor: Colors.gray2, flexDirection: 'column' }}>
        {isMobile && renderMobileHeader()}
        <Results full card mobile={isMobile}>
          <NFTImageContainer
            mobile={isMobile}
            main={location.pathname.split('/').length === 3}
          >
            <NFTImage
              mobile={isMobile}
              src={nftData.image}
              main={location.pathname.split('/').length === 3}
            />
          </NFTImageContainer>
          <Switch>
            <Route exact path="/nft/:id" component={NFTDetail} />
            <Route exact path="/market/:id/fund" component={AddFund} />
            <Route exact path="/market/:id/processing" component={Processing} />
            <Route exact path="/market/:id/success" component={Completion} />
            <Route exact path="/market/:id/redeem" component={Redeem} />
          </Switch>
        </Results>
      </Main>
    </PageFrame>
  );
};

export default NFTSingle;
