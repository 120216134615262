import { AxiosRequestConfig } from 'axios';
import { BigNumber } from 'ethers';

import { auth } from './firebase';

export * from './Enums';
export * from './promises';
export * from './firebase';
export * from './storyblok';
export * from './ScrollToTop';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const accountDisplayName = (accountName, myAccount) => {
  return accountName === myAccount
    ? 'Me'
    : accountName.substring(0, 14) + '...';
};

// Util function to update items in an array that match a predicate
// Often used when we have keyed items stored in an array rather than a hash, and we just want to update one of them
export function updateIf<T>(
  items: ReadonlyArray<T>,
  predicate: (item: T) => boolean,
  map: (item: T) => T,
): ReadonlyArray<T> {
  return items.map((item) => (predicate(item) ? map(item) : item));
}

export const getAxiosConfig = async () => {
  const userAuthToken = (await auth?.currentUser?.getIdToken()) || '';
  const axiosRequestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      token: userAuthToken,
    },
    validateStatus: () => {
      return true; // I'm always returning true, you may want to do it depending on the status received
    },
  };
  return axiosRequestConfig;
};

export const floatToWei = (amount: string) => {
  const parts = amount.split('.');
  const numberString = BigNumber.from(parts[0]).mul(BigNumber.from(10).pow(18));
  const decString =
    parts.length === 1
      ? BigNumber.from(0)
      : BigNumber.from(parts[1] || '0').mul(
          BigNumber.from(10).pow(18 - parts[1].length),
        );
  return numberString.add(decString).toString();
};
