import * as ActionTypes from '../ActionTypes';

export const setAddress = (data) => ({
  type: ActionTypes.SET_ADDRESS,
  data,
});

export const getErc20Balances = () => ({
  type: ActionTypes.GET_ERC20_BALANCE,
});

export const getErc20BalancesSuccess = (data) => ({
  type: ActionTypes.GET_ERC20_BALANCE_SUCCESS,
  data,
});

export const getErc20BalancesError = (data) => ({
  type: ActionTypes.GET_ERC20_BALANCE_ERROR,
  data,
});

export const setConnectionState = (data) => ({
  type: ActionTypes.CONNECT_ACCOUNT,
  data,
});

export const connectAccountSuccess = (data) => ({
  type: ActionTypes.CONNECT_ACCOUNT_SUCCESS,
  data,
});

export const connectAccountError = (data) => ({
  type: ActionTypes.CONNECT_ACCOUNT_ERROR,
  data,
});

export const getWorldBalances = (data) => ({
  type: ActionTypes.GET_WORLD_BALANCE,
  data,
});

export const getworldBalancesSuccess = (data) => ({
  type: ActionTypes.GET_WORLD_BALANCE_SUCCESS,
  data,
});

export const getWorldBalancesError = (data) => ({
  type: ActionTypes.GET_WORLD_BALANCE_ERROR,
  data,
});

export const getAllowanceFor = (data: {
  address: string;
  token: string;
  spender: string;
}) => ({
  type: ActionTypes.GET_ALLOWANCE,
  data,
});

export const getAllowanceSuccess = (data: {
  token: string;
  spender: string;
  allowance: string;
}) => ({
  type: ActionTypes.GET_ALLOWANCE_SUCCESS,
  data,
});

export const getAllowanceError = (data) => ({
  type: ActionTypes.GET_ALLOWANCE_ERROR,
  data,
});
