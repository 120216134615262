import React from 'react';
// components
import {
  Wrapper,
  LoaderBg,
  InnerLoader,
  InnerDivFirst,
  InnerDivSecond,
  InnerDivThird,
} from './style';

interface Props {
  isBgBlur?: boolean;
}

export const LoadingIndicator = ({ isBgBlur = false }: Props) => {
  return (
    <Wrapper>
      <LoaderBg blur={isBgBlur}>
        <InnerLoader>
          <InnerDivFirst />
          <InnerDivSecond />
          <InnerDivThird />
        </InnerLoader>
      </LoaderBg>
    </Wrapper>
  );
};
