import { createGlobalStyle } from 'styled-components';
import { WindowSize } from './components/cmsdriven';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle(() => ({
  body: {
    margin: 0,
    padding: 0,
    fontFamily: 'Open-Sans, Helvetica, Sans-Serif',
    fontSize: '1rem',

    p: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: theme.fontSizes[1],
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[2],
      },
    },
    h1: {
      fontWeight: 700,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: theme.fontSizes[6],
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[7],
      },
    },
    h2: {
      fontWeight: 700,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: theme.fontSizes[5],
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[6],
      },
    },
    h3: {
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: theme.fontSizes[4],
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[5],
      },
    },
    h4: {
      fontSize: theme.fontSizes[2],
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[3],
      },
    },
    h5: {
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: theme.fontSizes[1],
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[2],
      },
    },
    h6: {
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: theme.fontSizes[0],
      [WindowSize.desktop]: {
        fontSize: theme.fontSizes[1],
      },
    },
  },
}));

export default GlobalStyle;
