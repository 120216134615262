import { call, select, put, takeLatest } from 'redux-saga/effects';
import { FarmContract, FarmData } from 'src/contracts';
import { getFarmContract } from '..';
import { GET_FARM_DATA, STORE_CONTRACTS } from '../ActionTypes';
import * as GetBondedActions from './Actions';

async function getFarmBalances(farmContract: FarmContract): Promise<FarmData> {
  const address = await farmContract.signer.getAddress();
  console.log('getFarmData address:', address);
  const farmData = await farmContract.getBalances(address);
  console.log('getFarmData farmData:', farmData);
  return farmData;
}

function* getFarmData() {
  try {
    const farmContract: FarmContract = yield select(getFarmContract);
    const farmData = yield call(getFarmBalances, farmContract);
    yield put(GetBondedActions.getFarmDataSuccess(farmData));
  } catch (e) {
    yield put(GetBondedActions.getFarmDataError(e));
  }
}

export function* storeContractsSaga() {
  yield takeLatest(STORE_CONTRACTS, getFarmData);
}

export function* getFarmDataSaga() {
  yield takeLatest(GET_FARM_DATA, getFarmData);
}
