// @flow

import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from './Sagas';
import { reducer as Account } from './Account/Reducer';
import { reducer as Contracts } from './Contracts/Reducer';
import { reducer as ERC20 } from './ERC20/Reducer';
import { reducer as Stories } from './Stories/Reducer';
import { reducer as Worlds } from './Worlds/Reducer';
import { AppState } from './CreateStore';

export default () => {
  const rootReducer = combineReducers({
    Account,
    Contracts,
    ERC20,
    Worlds,
    Stories,
  });

  return configureStore(rootReducer, rootSaga);
};

export const getAddress = (state: AppState) => state?.Account?.address;
export const getFarmContract = (state: AppState) =>
  state?.Contracts?.contracts?.farm;
