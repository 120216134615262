export const Colors = {
  gray1: '#fdf9ff',
  gray2: '#E5E5E5',
  gray3: '#DFDFDF',
  gray4: '#d7d3dd',
  gray5: '#C4C4C4',
  gray6: '#6D6D6D',
  gray7: '#555555',
  darkGray: '#333333',
  white: '#FFF',
  black: '#000',
  transparentBlack: '#00000080',
  transparent: '#0000',
  red: '#FA3C6F',
  blue: '#3498db',
  orange: '#FD7A24',
  lightGreen: '#78C637',
  green: '#01998B',
  brown: '#332244'
};
