import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// hooks
import { useWindowSize } from '../../../hooks/useWindowSize';
import { NFTCard } from 'src/components/nftCard';
import { MobileProps } from 'src/components';

const Wrapper = styled.div<MobileProps>((props) => ({
  padding: props.mobile ? '8px' : '16px 20px',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'auto',
  justifyContent: 'center',
}));

export const ResultsBody = () => {
  const { isMobile, windowSize } = useWindowSize();
  const [marginRight, setMarginRight] = useState(0);

  const data = [
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/OGDNkQIWqH2flzAey2w5OICZOfEsF51SkA3n5L_BMRqe4nTxlI5DEnrUWQGRw4vtbVK-4WG6C1WPw3VhXpDk97_HIhRCd6yyUnonbg=s341',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/XlLL3euFkGYj4V8uqiWZ0rnOdYG3fRt7Yaz1EA-7tYRejWq1-oelj9TmUe4z6VNCVO0OZc_IJ8NOjarIWKZ2Qj8Esz9B3gAE-kJlqQ=s341',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/hImYwj_jogK1fJE5I6nIm-o0qR8L_H4u5MyvKTax8gPCW_80gNEcDwxcIa_nm3EOzZvwtkamRKXqyXt4JBLt9XPLOzk_XuekXGkXpw=s341',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
    {
      image:
        'https://lh3.googleusercontent.com/gj47nmAR3valkmpVbwamiuTJfWEWSCyVeORdjM6DRWrZ1o8WaqBxFXmpBrzZnGoWaPwq1Y0jiXRrBLbnLcawAp92=s120',
      col: 'col1',
      name: 'item1',
      price: 1,
    },
  ];

  useEffect(() => {
    if (isMobile) {
      setMarginRight(data.length % 2 === 1 ? windowSize.width / 2 - 8 : 0);
    } else {
      const numCols = Number(((windowSize.width - 40) / 312 - 0.5).toFixed(0));
      const diff = numCols - (data.length % numCols);
      setMarginRight(diff * 312);
    }
  }, [windowSize.width, isMobile]);

  return (
    <Wrapper mobile={isMobile}>
      {data.map((d, i) => (
        <NFTCard
          key={`${d.name}-${i}`}
          nftData={d}
          index={i}
          redirectUrl="/nft/123"
        />
      ))}
    </Wrapper>
  );
};
