import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { MobileProps } from '..';

const Item = styled.div({
  background: '#FFFFFF',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '12px',
  display: 'block',
  width: '288px',
  height: '468px',
  cursor: 'pointer',
});

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  position: relative;
  height: 180px;
`;

const ImageContainer = styled.div({
  backgroundColor: '#96B3BC',
  width: '100%',
});

const Image = styled.img({
  width: '100%',
  aspectRatio: '1',
  objectFit: 'contain',
});

const ItemDescription = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 12px;
`;

const ItemTitle = styled.div<MobileProps>(
  (props) => `
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: ${props.mobile ? '15px' : '22px'};
        line-height: ${props.mobile ? '16px' : '24px'};
        height: ${props.mobile ? '32px' : '48px'};
        color: #000000;
        margin-bottom: ${props.mobile ? '16px' : 'auto'};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    `,
);

const ItemPrice = styled.div<MobileProps>(
  (props) => `
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: ${props.mobile ? '15px' : '22px'};
        line-height: ${props.mobile ? '16px' : '24px'};
        display: flex;
        align-items: flex-end;
        color: ${props.theme.colors.red};
        white-space: pre-wrap;
    `,
);

const ItemIndex = styled.div<MobileProps>(
  (props) => `
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: ${props.mobile ? '12px' : '16px'};
        line-height: ${props.mobile ? '16px' : '24px'};
        display: flex;
        align-items: flex-end;
        text-align: right;
        color: #000000;
        position: absolute;
        right: ${props.mobile ? '8px' : '12px'};
        bottom: ${props.mobile ? '8px' : '12px'};
    `,
);

export const NFTCard = ({
  redirectUrl = '',
  nftData,
  index,
  marginRight = 0,
}) => {
  const history = useHistory();
  const { isMobile, windowSize } = useWindowSize();

  return (
    <Item
      key={index}
      onClick={() => history.push(redirectUrl)}
      style={
        isMobile
          ? {
            width: windowSize.width / 2 - 24,
            height: windowSize.width / 2 + 64,
            margin: '8px',
            marginRight: marginRight + 8,
          }
          : { marginRight: marginRight + 12 }
      }
    >
      <ImageContainer>
        <Image src={nftData.image} />
      </ImageContainer>
      <ItemInfo
        style={isMobile ? { height: '88px', padding: '16px 8px 8px' } : {}}
      >
        {!isMobile && <ItemDescription>{`Creator's name`}</ItemDescription>}
        <ItemTitle mobile={isMobile}>{nftData.name}</ItemTitle>
        <ItemPrice mobile={isMobile}>
          {isMobile ? '9999 G' : '9999\nGAME'}
        </ItemPrice>
        <ItemIndex mobile={isMobile}>1 / 100</ItemIndex>
      </ItemInfo>
    </Item>
  );
};

NFTCard.propTypes = {
  redirectUrl: PropTypes.string,
  nftData: {
    name: PropTypes.string,
    image: PropTypes.string,
  },
  index: PropTypes.number,
  marginRight: PropTypes.number,
};
