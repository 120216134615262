// @flow

import {
  GET_STORIES,
  GET_STORIES_SUCCESS,
  GET_STORIES_ERROR,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';
import { StoryStoryblok } from 'src/typings';

export type StoriesType = {
  stories: { [slug: string]: StoryStoryblok };
  isFetching: boolean;
  error: any;
};

const INITIAL_STATE = {
  stories: {},
  isFetching: false,
  error: null,
};

const reducers = {
  [GET_STORIES]: (state) => ({ ...state, isFetching: true }),
  [GET_STORIES_SUCCESS]: (state, { data }) => {
    const stories = {};
    (data as StoryStoryblok[]).map((story) => {
      stories[story.full_slug] = story;
    });
    return {
      ...state,
      stories: stories,
      isFetching: false,
    };
  },
  [GET_STORIES_ERROR]: (state, { error }) => ({
    ...state,
    stories: [],
    error,
    isFetching: false,
  }),
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
