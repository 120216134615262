import axios from 'axios';
import { moralisOptions } from './moralisOptions';

export const getPolygonBalances = async (address: string) => {
  const balances = { erc20: {}, matic: '0' };
  console.log('getPolygonBalances address', address);
  try {
    const axiosErc20Return = await axios.get(
      `https://deep-index.moralis.io/api/v2/${address}/erc20?chain=polygon`,
      moralisOptions,
    );

    console.log(axiosErc20Return);
    if (axiosErc20Return.status === 200 && !!axiosErc20Return.data) {
      axiosErc20Return.data.map((bal) => {
        balances.erc20[bal.token_address] = bal;
      });
    } else {
      console.log(`failed to get balances for ${address}`);
    }

    const axiosBalanceReturn = await axios.get(
      `https://deep-index.moralis.io/api/v2/${address}/balance?chain=polygon`,
      moralisOptions,
    );

    if (axiosBalanceReturn.status === 200 && axiosBalanceReturn.data) {
      balances.matic = axiosBalanceReturn.data.balance;
    } else {
      console.log(`failed to get balances for ${address}`);
    }
  } catch (err) {
    console.error('failed to getPolygonBalances', err);
  }
  console.log(balances);
  return balances;
};
