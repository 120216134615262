// @flow
import { fork } from 'redux-saga/effects';
import createERC20DataSagas from './ERC20/Sagas';
import createStoryDataSagas from './Stories/Sagas';
import {
  getErc20BalancesSaga,
  getWorldBalancesSaga,
  setAddressSaga,
} from './Account/Sagas';
import { getFarmDataSaga, storeContractsSaga } from './Contracts/Sagas';

export default function* root() {
  yield fork(createERC20DataSagas);
  yield fork(createStoryDataSagas);
  yield fork(getErc20BalancesSaga);
  yield fork(getWorldBalancesSaga);
  yield fork(setAddressSaga);
  yield fork(getFarmDataSaga);
  yield fork(storeContractsSaga);
}
