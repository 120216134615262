// game (approve)
// farm (farm, redeem)
// genesis (approve)
// governance (stake, unstake)
// world (buy, sell)
// usdc/usdt/weth/wmatic/quick

import { Contract, Signer } from 'ethers';
import { contract } from '.';
import abis from './abis';
import {
  FarmContract,
  GovernanceContract,
  Erc20Contract,
} from './ContractTypes';

export type Contracts = {
  game: Erc20Contract;
  genesis: Erc20Contract;
  governance: GovernanceContract;
  farm: FarmContract;
  usdc: Erc20Contract;
  usdt: Erc20Contract;
  weth: Erc20Contract;
  quick: Erc20Contract;
};

export function loadContracts(signer: Signer): Contracts {
  // erc20 contracts
  const game = new Contract(contract.side.game, abis.erc20).connect(
    signer,
  ) as Erc20Contract;
  const genesis = new Contract(contract.side.genesis, abis.erc20).connect(
    signer,
  ) as Erc20Contract;
  const governance = new Contract(contract.side.governance, abis.erc20).connect(
    signer,
  ) as GovernanceContract;
  const farm = new Contract(contract.side.farm, abis.erc20).connect(
    signer,
  ) as FarmContract;
  const usdc = new Contract(contract.side.usdc, abis.erc20).connect(
    signer,
  ) as Erc20Contract;
  const usdt = new Contract(contract.side.usdt, abis.erc20).connect(
    signer,
  ) as Erc20Contract;
  const weth = new Contract(contract.side.weth, abis.erc20).connect(
    signer,
  ) as Erc20Contract;
  const quick = new Contract(contract.side.quick, abis.erc20).connect(
    signer,
  ) as Erc20Contract;

  // usdc/t/etc
  return {
    game,
    genesis,
    governance,
    farm,
    usdc,
    usdt,
    weth,
    quick,
  };
}
