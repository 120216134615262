import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';

import { Main } from 'src/components';
import { PageFrame } from 'src/components/page';
import { ResultsHeader } from './components/ResultsHeader';
import { ResultsBody } from './components/ResultsBody';
import { CreatorHeader } from './components/CreatorHeader';

import { useWindowSize } from 'src/hooks/useWindowSize';
import { myntyStoreRef } from 'src/util';

export const Results = styled.div({
  flex: '1 0 0%',
  width: '100%',
  height: '100%',
});

const Creator = () => {
  const params = useParams();
  const history = useHistory();
  const {} = useWindowSize();
  const [, setShowFilter] = useState(true);
  const [, setCreator] = useState(null);

  useEffect(() => {
    myntyStoreRef.on(
      'value',
      (snapshot) => {
        const { creators, creatorurls } = snapshot.val();
        if (creatorurls[params['id']]) {
          setCreator(creators[creatorurls[params['id']]]);
        } else {
          history.push('/creators');
        }
      },
      (err) => console.log(err),
    );
  }, []);

  return (
    <PageFrame>
      <Main style={{ backgroundColor: '#E5E5E5' }}>
        <Results>
          <ResultsHeader onShowFilter={() => setShowFilter(true)} />
          <CreatorHeader></CreatorHeader>
          <ResultsBody />
        </Results>
      </Main>
    </PageFrame>
  );
};

export default Creator;
