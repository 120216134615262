import { StoryblokDesktopLayout } from 'src/typings';
import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import {
  desktopGutter,
  GenesisPadding,
  maxContentWidth,
  WindowSize,
} from '../constants';

type WrapperProps = SpaceProps &
  ColorProps & { layout?: StoryblokDesktopLayout } & { hasCta?: boolean };

export const PageSectionStyle = styled.div<WrapperProps>(
  (props) => ({
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    [WindowSize.desktop]: {
      flexDirection:
        props.layout !== StoryblokDesktopLayout.full ? 'row' : 'column',
    },
    justifyContent: 'space-between',
  }),
  space,
  color,
);

enum ArtHeight {
  mobile = '200px',
  leftRight = '400px',
  fullWidth = '400px',
}

function getArtWidth(isFullWidth: boolean) {
  return isFullWidth ? '100%' : `calc(calc(100% - ${desktopGutter}) / 2)`;
}

function getTextWidth(isFullWidth: boolean) {
  return isFullWidth ? '100%' : `calc(calc(100% + ${desktopGutter}) / 2)`;
}

export function getPadding(
  isMobile: boolean,
  isFullWidth: boolean,
  hasCta: boolean,
) {
  if (isMobile) {
    return `0 ${GenesisPadding.mobileSingle} ${GenesisPadding.mobileSingle}`;
  } else if (isFullWidth) {
    return `${GenesisPadding.desktopSingle} ${GenesisPadding.desktopDouble} ${
      hasCta ? GenesisPadding.desktopDouble : GenesisPadding.mobileSingle
    }`;
  } else {
    return desktopGutter;
  }
}

export const PageSectionTextStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    const isLeft = !isFullWidth && props.layout === StoryblokDesktopLayout.left;
    return {
      //height: props.mobile ? '200px' : isFullWidth ? '240px' : 'unset',
      width: getTextWidth(isFullWidth),
      padding: getPadding(true, isFullWidth, false),
      [WindowSize.desktop]: {
        padding: getPadding(false, isFullWidth, props.hasCta),
      },
      margin: isFullWidth ? 'auto' : '0px',
      maxWidth: maxContentWidth,
      objectFit: 'cover',
      overflow: 'hidden',
      textAlign: isFullWidth ? 'center' : isLeft ? 'left' : 'right',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: isFullWidth ? 'center' : isLeft ? 'flex-start' : 'flex-end',
    };
  },
  space,
  color,
);

export const PageSectionParagraphStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    const isLeft = !isFullWidth && props.layout === StoryblokDesktopLayout.left;
    return {
      textAlign: isFullWidth || isLeft ? 'left' : 'right',
    };
  },
  space,
  color,
);

export const ArtBoxCutoutStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    return {
      height: ArtHeight.mobile,
      minHeight: ArtHeight.mobile,
      [WindowSize.desktop]: {
        height: isFullWidth ? ArtHeight.fullWidth : '',
        minHeight: isFullWidth ? ArtHeight.fullWidth : ArtHeight.leftRight,
      },
      width: getArtWidth(isFullWidth),
      maxWidth: '100%',
      position: 'absolute',
    };
  },
  space,
  color,
);

export const PageSectionArtBoxStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    return {
      height: ArtHeight.mobile,
      minHeight: ArtHeight.mobile,
      [WindowSize.desktop]: {
        height: isFullWidth ? ArtHeight.fullWidth : '',
        minHeight: isFullWidth ? ArtHeight.fullWidth : ArtHeight.leftRight,
      },
      width: getArtWidth(isFullWidth),
      maxWidth: '100%',
      position: 'relative',
    };
  },
  space,
  color,
);

export const PageSectionArtStyle = styled.img<WrapperProps>(
  (props) => {
    return {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    };
  },
  space,
  color,
);
