import React from 'react';
import {
  DataTableBody,
  DataTableData,
  DataTableHeader,
  DataTableLabel,
  DataTableRow,
  DataTableCta,
} from './style';

export interface TableData {
  title: string;
  rows: TableRowData[];
  actionButton: JSX.Element;
}

export interface TableRowData {
  data: string;
  label: string;
}

export function DataTable(table: TableData) {
  return (
    <DataTableBody>
      {table.title && (
        <DataTableHeader>
          <h5>{table.title}</h5>
        </DataTableHeader>
      )}
      {table?.rows?.length > 0 &&
        table.rows.map((r) => (
          <DataTableRow key={r.label}>
            <DataTableData>{r.data}</DataTableData>
            <DataTableLabel>{r.label}</DataTableLabel>
          </DataTableRow>
        ))}
      {table.actionButton && <DataTableCta>{table.actionButton}</DataTableCta>}
    </DataTableBody>
  );
}
