import {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/providers';

export async function sendTransaction(
  tx: TransactionResponse,
  callback: (receipt: TransactionReceipt) => void,
  onError: (error: any, receipt: TransactionReceipt) => void,
) {
  try {
    // Send the tx. Can wait for more than one confirmation if we really want to
    const receipt = await tx.wait().catch((reason) => {
      console.error(`tx ${tx.data} failed`, reason);
    });

    if (receipt) {
      console.log('tx receipt', receipt);
      if (receipt.status === 1) {
        callback(receipt);
      } else {
        onError('tx failed', receipt);
      }
    }
  } catch (error) {
    if (error.code === 'TRANSACTION_REPLACED') {
      if (error.cancelled) {
        // The transaction was replaced  :'(
        onError(error, error.receipt);
      } else {
        // The user used "speed up" or something similar
        // in their client, but we now have the updated info
        callback(error.receipt);
      }
    } else {
      onError(error, undefined);
    }
  }
}
