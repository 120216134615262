// @flow
import { FarmData } from 'src/contracts';
import {
  SET_ADDRESS,
  GET_ERC20_BALANCE,
  GET_ERC20_BALANCE_SUCCESS,
  GET_ERC20_BALANCE_ERROR,
  GET_ALLOWANCE,
  GET_ALLOWANCE_SUCCESS,
  GET_ALLOWANCE_ERROR,
  GET_WORLD_BALANCE,
  GET_WORLD_BALANCE_SUCCESS,
  GET_WORLD_BALANCE_ERROR,
  CONNECT_ACCOUNT,
  GET_FARM_DATA_SUCCESS,
  GET_FARM_DATA_ERROR,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';

export interface ContractBalances {
  farm: {
    [address: string]: Erc20Balance;
  };
  governance: {
    [address: string]: Erc20Balance;
  };
}

export interface AccountState {
  address: string;
  sideBalances: {
    matic: string;
    erc20: {
      [address: string]: Erc20Balance;
    };
  };
  mainBalances: {
    eth: string;
    erc20: {
      [address: string]: Erc20Balance;
    };
  };
  contractBalances: ContractBalances;
  worldsOwned: {
    [id: number]: number;
  };
  allowances: {
    [token: string]: { [spender: string]: string };
  };
  farmData: FarmData;
}

export interface AllBalances {
  sideBalances: {
    matic: string;
    erc20: {
      [address: string]: Erc20Balance;
    };
  };
  mainBalances: {
    eth: string;
    erc20: {
      [address: string]: Erc20Balance;
    };
  };
  contractBalances: {
    farm: {
      [address: string]: Erc20Balance;
    };
    governance: {
      [address: string]: Erc20Balance;
    };
  };
}

export interface Erc20Balance {
  balance: string;
  decimals: string;
  logo: string;
  name: string;
  symbol: string;
  thumbnail: string;
  token_address: string;
}

export const zeroBalances: AllBalances = {
  sideBalances: {
    matic: '0',
    erc20: {},
  },
  mainBalances: {
    eth: '0',
    erc20: {},
  },
  contractBalances: {
    farm: {},
    governance: {},
  },
};

export enum ConnectionState {
  none = 'none',
  noProvider = 'noProvider',
  connecting = 'connecting',
  connected = 'connected',
  loggingOut = 'logingOut',
  failed = 'failed',
}

const INITIAL_STATE: AccountState = {
  address: '0x000000000000000000000000000000000000000F',
  sideBalances: {
    matic: '0',
    erc20: {},
  },
  mainBalances: {
    eth: '0',
    erc20: {},
  },
  contractBalances: {
    farm: {},
    governance: {},
  },
  worldsOwned: {},
  allowances: {},
  farmData: {} as FarmData,
};

const reducers = {
  [SET_ADDRESS]: (state, { data }) => {
    console.log('set address');
    return {
      ...state,
      address: data,
    };
  },
  [GET_ERC20_BALANCE]: (state) => {
    return state;
  },
  [GET_ERC20_BALANCE_SUCCESS]: (state, { data }) => ({
    ...state,
    sideBalances: data.sideBalances,
    mainBalances: data.mainBalances,
    contractBalances: data.contractBalances,
  }),
  [GET_ERC20_BALANCE_ERROR]: (state, { error }) => ({
    ...state,
    sideBalances: zeroBalances.sideBalances,
    mainBalances: zeroBalances.mainBalances,
    contractBalances: zeroBalances.contractBalances,
  }),
  [GET_ALLOWANCE]: (state) => {
    return state;
  },
  [GET_ALLOWANCE_SUCCESS]: (state, { data }) => {
    const { spender, token, allowance } = data;
    const allowances = {};
    let foundKey = false;
    for (const key in state.allowances) {
      const newAllowance = { ...state.allowances[key] };
      if (key === token) {
        foundKey = true;
        newAllowance[spender] = allowance;
      }
      allowances[key] = newAllowance;
    }
    if (!foundKey) {
      allowances[token] = { [spender]: allowance };
    }
    return {
      ...state,
      allowances,
    };
  },
  [GET_ALLOWANCE_ERROR]: (state, { error }) => {
    return state;
  },
  [GET_WORLD_BALANCE]: (state) => {
    return state;
  },
  [GET_WORLD_BALANCE_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [GET_WORLD_BALANCE_ERROR]: (state, { error }) => ({
    ...state,
    worldsOwned: {},
  }),
  [CONNECT_ACCOUNT]: (state, { data }) => {
    return state;
  },
  [GET_FARM_DATA_SUCCESS]: (state, { data }) => ({
    ...state,
    farmData: data,
  }),
  [GET_FARM_DATA_ERROR]: (state, { error }) => ({
    ...state,
    farmData: {},
    error,
  }),
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
