import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { TitleProps } from 'src/components';
import { FontSize, GenesisPadding, WindowSize } from './constants';

type WrapperProps = SpaceProps & ColorProps & TitleProps;

export const SiteTitle = styled.h1<WrapperProps>(
  (props) => {
    return {
      textTransform: 'uppercase',
      boxSizing: 'border-box',
      textShadow: '0px 8px 10px #000000, 0px -4px 10px #000000',
      marginBottom: '16px',
    };
  },
  space,
  color,
);

export const Title = styled.h3<WrapperProps>(
  (props) => {
    return {
      textTransform: 'uppercase',
      margin: `${GenesisPadding.mobileSingle} 0`,
    };
  },
  (props) =>
    props.header
      ? {
          boxSizing: 'border-box',
          textShadow: '0px 8px 10px #000000, 0px -4px 10px #000000',
          marginBottom: '16px',
        }
      : {},
  space,
  color,
);

export const Subtitle = styled.h1<WrapperProps>(
  (props) => {
    return {
      font: 'Montserrat',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: FontSize.mobileSubHead,
      // lineHeight: props.header ? '24px' : '30px',
      [WindowSize.desktop]: {
        fontSize: FontSize.desktopSubHead,
      },
    };
  },
  (props) =>
    props.header
      ? {
          boxSizing: 'border-box',
          textShadow:
            '0px -4px 10px rgba(0, 0, 0, 0.5), 0px 4px 10px rgba(0, 0, 0, 0.5)',
        }
      : {},
  space,
  color,
);

export const SiteCtaButtonStyle = styled.div<WrapperProps>(
  (props) => {
    if (props.header) {
      return {
        width: '100vw',
        padding: GenesisPadding.mobileDouble,
        [WindowSize.desktop]: {
          padding: GenesisPadding.desktopDouble,
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    } else {
      return {
        padding: GenesisPadding.mobileSingle,
        [WindowSize.desktop]: {
          padding: GenesisPadding.desktopSingle,
        },
      };
    }
  },
  space,
  color,
);
