import React from 'react';
import { FunctionalSectionType } from 'src/typings';
import { FarmGenesisSection } from 'src/components/datadriven';

interface FunctionalSectionProps {
  type: FunctionalSectionType;
}

export function FunctionalSection(props: FunctionalSectionProps) {
  console.log('FunctionalSection', props);
  switch (props.type) {
    case FunctionalSectionType.Farming:
      return <FarmGenesisSection />;
    case FunctionalSectionType.World:
      return <FarmGenesisSection />;
    case FunctionalSectionType.Governance:
      return <FarmGenesisSection />;
    default:
      return <></>;
  }
}
