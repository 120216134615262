import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';

type WrapperProps = SpaceProps &
  ColorProps & {
    top: number;
    height: number;
    dropUp: number;
  };

export const Wrapper = styled.div<WrapperProps>(
  (props) => ({
    position: 'absolute',
    zIndex: 1000,
    top: props.top,
    height: props.height,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    border: `1px solid ${props.theme.colors.gray6}`,
    borderWidth: props.dropUp ? '0 0 1px 0' : '1px 0 0',
  }),
  space,
  color,
);

export const MenuOption = styled.div`
  height: 40px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.gray6};
  font-weight: 500;
  font-family: Montserrat;
`;
